<template>
  <v-row justify="center">

    <div style="background-color: #fff;">
      <v-row justify="center">
        <v-col cols="12" xs="12" lg="10" xl="10">
          <div style="padding: 150px;" :class="$vuetify.breakpoint.xs || $vuetify.breakpoint.md ? 'pa-5' : 'pa-15'">
            <v-row justify="center">
              <v-col cols="12" xs="12" sm="10" md="6" lg="6">
                <div>

                  <h2
                    :class="$vuetify.breakpoint.xs ? 'display-1 text-center mt-10' : $vuetify.breakpoint.sm ? 'display-2' : ''"
                    style="color: #1f2933; font-size: 48px; font-weight: 700;">
                    {{ $t('simpleEarn.phrase1') }}
                  </h2>

                  <div class="mt-10" style="color: #1f2933; font-size: 18px; font-weight: 500;">
                    {{ $t('simpleEarn.phrase2') }}
                  </div>
                </div>
              </v-col>

              <v-col cols="12" xs="12" sm="8" md="6" lg="6" align="center">
                <div>
                  <v-img max-height="300px" max-width="300px" style="border-radius:10px"
                    :src="require('../../../assets/images/earn2.jpg')"></v-img>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </div>

    <v-col cols="12" xs="12" lg="8" class="my-10">

      <!-- <v-toolbar class="transparent elevation-0">
        <v-btn icon @click="goBack()">
          <icon icon="material-symbols:arrow-back" width="24" height="24"></icon>
        </v-btn>
        <v-toolbar-title>Simple Earn</v-toolbar-title>
      </v-toolbar> -->

      <v-row dense align="center" class="my-5">
        <v-col cols="12" xs="12" sm="6">
          <h2> {{ $t('simpleEarn.allProducts') }} </h2>
        </v-col>

        <v-col align="end" cols="12" xs="12" sm="6">
          <main-earn-toolbar :search.sync="search" />
        </v-col>
      </v-row>

      <main-earn-coin-list-simple :search="search" />
    </v-col>

  </v-row>
</template>
<script>
import store from "@/store";
export default {
  name: "earn",
  data: () => ({
    search: "",
  }),
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    walletSelected() {
      this.$store.commit("setWalletView", "tab-4");
      this.$router.push("/wallet");
    },
  },
  mounted() { },
  beforeRouteEnter(to, from, next) {
    const isAuthenticated = store.getters.isAuthenticated;
    if (isAuthenticated) {
      next();
    } else {
      next("/signin");
    }
  },
};
</script>
