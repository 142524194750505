<template>
    <v-row>

        <v-col cols="12">

            <div class="mt-5" v-if="!$vuetify.breakpoint.xs">
                <v-card class="pa-5" color="#00000000" style="border-radius: 5px; border: 1px solid #b7bdc6 ;">
                    <v-data-table :loading="isLoading" :headers="headers" :items="withdrawHistory" :items-per-page="20"
                        @click:row="rowClicked($event)" class="transparent">

                        <template v-slot:item.name="{ item }">
                            <div class="py-3 d-flex align-center">
                                <div class="mr-3">
                                    <v-avatar size="30">
                                        <v-img :src="item.image"></v-img>
                                    </v-avatar>
                                </div>

                                <div class="mr-2 d-flex align-center">
                                    <!-- <span class="mr-2" style="font-size:16px;">{{ item.coin_pair_name }}</span><br /> -->
                                    <span class="">{{ (item.name) }}</span>
                                    <!-- <span style="font-size: 14px;"> {{ item.date }}</span><br /> -->
                                </div>
                            </div>
                        </template>

                    </v-data-table>
                </v-card>
            </div>

            <div v-else>

                <div v-if="withdrawHistory.length <= 0">

                    <div class="d-flex align-center justify-center mt-10" style="height: 100%">
                        <div class="d-flex flex-column align-center justify-center">
                            <v-icon x-large color="#b7bdc6">
                                mdi mdi-note-search-outline
                            </v-icon>
                            <span style="font-size: 12px; color: #b7bdc6">No Records Found</span>
                        </div>
                    </div>

                </div>

                <div v-else>
                    <v-row v-for=" { client_wallet, withdraw_amount, application_time, state, } in withdrawHistory"
                        justify="center" class="mt-3 py-2" style="border-bottom: 1px solid #2b3139;">
                        <v-col sm="6" align="start">
                            <div class="d-flex flex-column">
                                <div class="d-flex align-center">
                                    <span style="font-size: 14px; font-weight: 500"> {{ client_wallet.coin.name }}</span>
                                </div>
                                <span style="font-size: 14px; font-weight: 500;">{{ state }}</span>
                                <span style="font-size: 12px; color: #b7bdc6 ;">{{ application_time }}</span>
                            </div>
                        </v-col>

                        <v-col sm="6" align="end">
                            <span style="font-size: 14px; font-weight: 500; color: #CA3F64   ;">
                                {{ withdraw_amount }}
                            </span>

                            <!-- <span  style="font-size:10px;color:grey">${{ parseFloat(item.usdt_value).toFixed(2) }}</span> -->
                        </v-col>
                    </v-row>

                </div>
            </div>

        </v-col>

        <!-- <SearchBarComponent /> -->

        <!-- Loading indicator -->
        <!-- <div v-if="isLoading" class="text-center">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div> -->

    </v-row>
</template>

<script>

export default {
    props: {
        withdrawHistory: {
            type: Array,
            required: false,
        }
    },
    data: () => ({

        headers: [
            {
                value: "client_wallet.coin.name",
                text: "Asset",
                align: "start",
                sortable: true,
            },

            {
                value: "withdraw_amount",
                text: "Amount",
                sortable: true,
                align: "start",
            },

            {
                value: "application_time",
                text: "Date",
                align: "start",
                sortable: true,
            },
            {
                value: "state",
                text: "status",
                sortable: true,
                align: "center",
            },
        ],
    }),
}

</script>