<template>
    <v-dialog v-model="bindData" fullscreen hide-overlay transition="dialog-bottom-transition" max-width="500px">
        <v-card class="elevation-0" style="padding: 20px">

            <div class="d-flex align-center justify-space-between pa-5">
                <v-toolbar-title>{{ dialogTitle }}</v-toolbar-title>

                <v-btn x-small icon @click="handleCloseDialog">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </div>

            <v-card-text>
                <slot></slot>
            </v-card-text>

        </v-card>
    </v-dialog>
</template>


<script>
export default {
    props: {
        bindData: {
            type: [Boolean, Function],
            required: false
        },

        dialogTitle: {
            type: String,
            required: false
        },

        dialogContent: {
            type: String,
            required: false
        },
    },

    methods: {
        handleCloseDialog() {
            this.$emit('update:bindData', false); // Emit event to update the bindData prop in the parent
        }
    }
}
</script>