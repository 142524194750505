<template>
  <v-card class="weird-card">
    <v-card-text>
      <v-row justify="space-between">
        <v-col>
          <h4 class="font-weight-bold mb-0 white--text">Asset Distribution</h4>
        </v-col>
        <v-col class="text-end">
          <!-- <v-btn-toggle v-model="chartType" color="primary" mandatory rounded>
            <v-btn icon small>
              <icon
                icon="teenyicons:donut-chart-solid"
                width="18"
                height="18"
              ></icon>
            </v-btn>
          </v-btn-toggle> -->
          <!-- Show PNL -->
          <span class="leading-none">PNL</span><br />
          <span class="pnl-text">{{ pnl.value | commaFormat }}</span
          ><br />
          <span :class="percentColor">{{ pnl.percentage }} %</span>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" md="6">
          <div>
            <apexchart
              ref="assetPieChart"
              type="donut"
              :options="pieChartOptions"
              :series="pieSeries"
            ></apexchart>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: {
    pieChartData: {
      type: Object,
    },
    pnl: {
      type: Object,
    },
  },
  data() {
    return {
      chartType: 1,

      pieSeries: this.pieChartData.series,
      pieChartOptions: {
        chart: {
          type: "donut",
          background: "#1E1E1E",
        },
        colors: [
          "#00b5ad",
          "#4d4d4d",
          "#337ab7",
          "#5cb85c",
          "#f0ad4e",
          "#d9534f",
        ],
        theme: {
          mode: "dark",
        },
        plotOptions: {
          pie: {
            donut: {
              size: "75%",
            },
            stroke: {
              show: false,
            },
          },
        },
        dataLabels: {
          enabled: false,
          stroke: {
            colors: ["#2c3e50"],
            width: 2,
          },
        },
        labels: this.pieChartData.labels,
        //Remove white stroke per slice
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 350,
              },
              legend: {
                position: "right",
              },
            },
          },
        ],
      },
    };
  },
  computed: {
    percentColor() {
      //Return the color based on the percent value if positive or negative
      return this.pnl.percentage >= 0
        ? "greenPrimary--text"
        : "redPrimary--text";
    },
  },
  methods: {
    showPieChart() {
      console.log(this.pieChartData);
      this.$refs.assetPieChart.updateSeries(
        JSON.parse(this.pieChartData.series),
        true
      );
    },
  },
};
</script>
<style>
.weird-card {
  margin-top: 20px !important;
  width: 100%;
  margin: 0;
  padding: 0;
}

.pnl-text {
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
}
</style>
