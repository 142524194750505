<template>
    <v-row justify="center">
        <v-col cols="12" xs="12" lg="6">
            <PieChartComponent :chartData="chartData" :series="series" :labels="labels" />
        </v-col>
    </v-row>
</template>

<script>
import PieChartComponent from '@/components/PieChartComponent.vue';
export default {
    name: "tradingData",

    components: {
        PieChartComponent,
    },

    data() {
        return {
            chartData: [
                { label: 'Category 1', value: 30 },
                { label: 'Category 2', value: 50 },
                { label: 'Category 3', value: 20 }
            ],

            series: [113.1857, 121.97424, 48.03958, 109.11456, 74.99378, 30.31012],

            labels: ['Large Sells', 'Medium Sells', 'Small Sells', 'Large Buy', 'Medium Buy', 'Small Buy'],
        };
    }

}
</script>