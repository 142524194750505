<template>
  <v-row>
    <v-col>
      <h1 class="font-weight-bold" style="margin-bottom:50px">Popular Coins</h1>
      <div class="scrollable-content" v-show="!loading">
        <main-home-market-coin v-for="coin in filteredCoins" :key="coin.id" :name="coin.name" :image="coin.image"
          :percent="coin.percent" :amount="coin.amount" />
      </div>
      <div v-show="loading">
        <v-skeleton-loader v-show="loading" v-for="index in 10" :key="index" type="list-item-two-line"
          class="mt-3"></v-skeleton-loader>
      </div>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data: () => ({
    loading: true,
  }),
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
  computed: {
    marketCoins() {
      return this.$store.getters.marketCoins;
    },
    filteredCoins() {
      return this.marketCoins.filter((coin) => coin.type === "MAIN");
    },
  },
};
</script>
<style>
.v-tab {
  min-width: 60px !important;
}

.theme--dark.v-tabs-items {
  background-color: transparent !important;
}
</style>
