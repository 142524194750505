<template>
    <v-card class="pa-5" color="#272727" style="border-radius: 20px;">

        <div v-if="!homeCoins" class="text-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>

        <div v-else>
            <v-row align="center" v-for="{ image, value, name, percent } in homeCoins" :key="name">
                <v-col xs="4">
                    <div class="d-flex align-center justify-space-between">
                        <div class="d-flex align-center">
                            <v-avatar color="white" size="32" outlined>
                                <v-img alt="coin-avatar" :src="image"></v-img>
                            </v-avatar>
                            <span style="font-size: 16px; font-weight:500" class="ml-3">
                                {{ name }}
                            </span>
                        </div>
                    </div>
                </v-col>

                <v-col xs="4">

                    <span v-if="name == 'BTC'" style="font-size: 16px; font-weight:500">{{ parseFloat(btc).toFixed(2)
                    }}</span>
                    <span v-if="name == 'ETH'" style="font-size: 16px; font-weight:500">{{ parseFloat(eth).toFixed(2)
                    }}</span>
                    <span v-if="name == 'ADA'" style="font-size: 16px; font-weight:500">{{ parseFloat(ada).toFixed(2)
                    }}</span>
                    <span v-if="name == 'XRP'" style="font-size: 16px; font-weight:500">{{ parseFloat(xrp).toFixed(2)
                    }}</span>
                    <span v-if="name == 'SOL'" style="font-size: 16px; font-weight:500">{{ parseFloat(sol).toFixed(2)
                    }}</span>
                    <span v-if="name == 'SHIB'" style="font-size: 16px; font-weight:500">{{ parseFloat(shib).toFixed(2)
                    }}</span>
                </v-col>

                <v-col xs="4">
                    <span style="font-size: 16px; font-weight:500" :style="{ color: percent < 0 ? '#F6465D' : '#0ECB81' }">
                        {{ parseFloat(percent).toFixed(2) }}%</span>
                </v-col>
            </v-row>

            <v-divider></v-divider>

            <div :class="$vuetify.breakpoint.xs ? 'text-center' : 'text-left'">
                <v-btn text @click=" $router.push('/markets/trading_data')">
                    View All Coins
                </v-btn>
            </div>
        </div>


    </v-card>

    <!-- <v-row v-else justify="center">
        <v-col lg="12">
            <template v-for="{ image, value, name, percent } in marketCoins">

                <div class="d-flex align-center justify-space-between" :key="name">
                    <div class="d-flex align-center pa-2">
                        <v-avatar size="32" outlined>
                            <v-img alt="coin-avatar" :src="image"></v-img>
                        </v-avatar>
                        <span class="ml-3">
                            {{ name }}
                        </span>
                    </div>

                    <div class="mr-3">
                        {{ value }}
                    </div>

                    <div>
                        <span :style="{ color: percent < 0 ? '#F6465D' : '#0ECB81' }">{{ formattedPercent(percent)
                        }}</span>
                    </div>
                </div>
            </template>
        </v-col>
    </v-row> -->
</template>

<script>

import numeral from 'numeral';

export default {

    props: {
        isLoading: Boolean,
    },

    data() {
        return {
            isLoading: false,
            homeCoins: null,
            timer1: '',
            timer2: '',
            intervalId: null, // Initialize intervalId to null
            btc: '',
            btcPercent: '',
            eth: '',
            ethPercent: '',
            ada: '',
            adaPercent: '',
            xrp: '',
            xrpPercent: '',
            sol: '',
            solPercent: '',
        };
    },

    computed: {
        // Map the mainPairs data from Vuex store to a local computed property
        marketCoins() {
            return this.homeCoins.slice(0, 5)
        },

        formattedPrice() {
            return (value) => numeral(value).format('$0,0.00')
        },

        formattedPercent(value) {
            return (value) => numeral(value).format('0.0%');
        }
    },

    methods: {
        fetchMarketCoins() {
            this.isLoading = true; // Set loading state before making the request

            this.$axios.get('https://api.zgcoinex.com/api/v1/home/coins')
                .then(response => {
                    // Update homeCoins with the received data
                    this.homeCoins = response.data.coinpair.slice(0, 4);
                })
                .catch(error => {
                    console.error('Error fetching market coins:', error);
                    // Perform specific error handling logic if needed
                })
                .finally(() => {
                    // Ensure isLoading is set back to false regardless of success or failure
                    this.isLoading = false;
                });
        },

        // BTC,ADA, NET, STG
        fetchCoins() {
            this.isLoading = false
            this.$axios.get('https://www.okx.com/api/v5/market/ticker', {
                params: {
                    instId: 'BTC-USD-SWAP'
                }
            })
                .then(response => {
                    this.btc = response.data.data[0]['last']
                    this.btcPercent = response.data.data[0]
                })

            this.$axios.get('https://www.okx.com/api/v5/market/ticker', {
                params: {
                    instId: 'ADA-USD-SWAP'
                }
            })
                .then(response => {
                    this.ada = response.data.data[0]['last']
                })

            this.$axios.get('https://www.okx.com/api/v5/market/ticker', {
                params: {
                    instId: 'ETH-USD-SWAP'
                }
            })
                .then(response => {
                    this.eth = response.data.data[0]['last']
                })

            this.$axios.get('https://www.okx.com/api/v5/market/ticker', {
                params: {
                    instId: 'XRP-USD-SWAP'
                }
            })
                .then(response => {
                    this.xrp = response.data.data[0]['last']
                })

            this.$axios.get('https://www.okx.com/api/v5/market/ticker', {
                params: {
                    instId: 'SOL-USD-SWAP'
                }
            })
                .then(response => {
                    this.sol = response.data.data[0]['last']
                })


        }
    },

    mounted() {
        this.fetchCoins()
        this.fetchMarketCoins()
        this.timer1 = setInterval(this.fetchCoins, 4000)
        this.timer2 = setInterval(this.fetchMarketCoins, 4000)

        // Dispatch the action to fetch data from the API and update the Vuex store
        // this.$store.dispatch('setMarketCoins')
        //     .then(() => {
        //         console.log('Market coins data:', this.marketCoins);
        //     })
        //     .catch((error) => {
        //         console.error("Error dispatching setMarketCoins action:", error);
        //     });
    },
    beforeDestroy() {
        clearInterval(this.timer1)
        clearInterval(this.timer2)
    }

};
</script>
