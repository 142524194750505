<template>
    <div>
        <v-card v-if="!loading" class="balance-card">
            <v-card-text>
                <v-row>
                    <v-col>
                        <span class="caption">Total Balance(USDT)</span>
                        <h2 class="font-weight-bold mb-0 greenPrimary--text">
                            {{ balance | commaFormat }}
                        </h2>
                        <span class="sub-caption"
                            >={{ balance | commaFormat }} USDT</span
                        >
                    </v-col>
                </v-row>
                <v-row justify="center" no-gutter>
                    <v-col cols="4" class="pr-1 mb-1"
                        ><v-btn to="/convert" block large color="secondary"
                            >Deposit
                        </v-btn></v-col
                    >
                    <v-col cols="4" class="pl-1 pr-1 mb-1"
                        ><v-btn to="/withdraw" block large color="greyPrimary"
                            >Withdraw
                        </v-btn></v-col
                    >
                    <v-col cols="4" class="pl-1 mb-1"
                        ><v-btn
                            @click="transferClicked()"
                            block
                            large
                            color="greyPrimary"
                            >Transfer
                        </v-btn></v-col
                    >
                </v-row>
            </v-card-text>
        </v-card>
        <v-container v-else full-screen-container>
            <v-row justify="center">
                <v-col cols="12" class="text-center">
                    <v-progress-circular
                        indeterminate
                        color="secondary"
                    ></v-progress-circular>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
export default {
    data() {
        return {
            loading: true,
            stakes: [],
            balance: 0,
        };
    },
    mounted() {
        this.fetchFutureWallet();
    },
    methods: {
        fetchFutureWallet() {
            this.loading = true;
            this.balance = this.$store.getters.futureWallet.walletTotal;
            this.loading = false;
        },
        transferClicked() {
            this.$store.commit("setTransferType", "FUNDING");
            this.$router.push("/transfer");
        },
    },
};
</script>
<style>
.balance-card {
    margin-top: 20px !important;
    border-radius: 30px 30px 0 0 !important;
    width: 100%;
    margin: 0;
    padding: 0;
}

.sub-caption {
    font-size: 10px;
}

.full-screen-container {
    height: 90vh;
    width: 90vw;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
