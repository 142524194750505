<template>
    <v-row>

        <v-col class="mt-2" v-if="$vuetify.breakpoint.xs" align="start" cols="12" xs="12" md="8" lg="6">
            <SearchBarComponent :search.sync="search" placeholder="Search Coin" />
        </v-col>

        <v-col v-if="!$vuetify.breakpoint.xs" cols="12">
            <!-- <v-card class="pa-5" color="#272727" style="border-radius: 10px;"> -->
            <v-data-table :loading="isLoading" :headers="headers" :items="filteredData" :items-per-page="20"
                @click:row="rowClicked($event)" class="transparent">

                <template v-slot:top>
                    <v-toolbar flat color="transparent">
                        <v-row>
                            <v-col cols="12" xs="12" md="6" lg="3">
                                <SearchBarComponent :search.sync="search" placeholder="Search Coin" />
                            </v-col>
                        </v-row>
                    </v-toolbar>
                </template>

                <template v-slot:item.name="{ item }">
                    <div class="py-3 d-flex align-center">
                        <div class="mr-3">
                            <v-avatar size="30">
                                <v-img :src="item.image"></v-img>
                            </v-avatar>
                        </div>

                        <div class="mr-2 d-flex align-center">
                            <!-- <span class="mr-2" style="font-size:16px;">{{ item.coin_pair_name }}</span><br /> -->
                            <span class="">{{ (item.name) }}</span>
                            <!-- <span style="font-size: 14px;"> {{ item.date }}</span><br /> -->
                        </div>
                    </div>
                </template>

                <template v-slot:item.balance="{ item }">
                    <span class="">{{ parseFloat(item.balance).toFixed(2) }}</span><br />
                    <span style="font-size:10px;color:grey">${{ parseFloat(item.usdt_value).toFixed(2) }}</span>
                </template>

                <template v-slot:item.accumulation="{ item }">
                    <span class="">{{ parseFloat(item.balance).toFixed(2) - parseFloat(item.base_balance).toFixed(2)
                    }}</span><br />
                    <!-- <span style="font-size:10px;color:grey">${{ parseFloat(item.usdt_value).toFixed(2) }}</span> -->
                </template>

                <template v-slot:item.duration="{ item }">
                    <span class="">{{ item.duration }} {{ $t('wallet.days') }}</span><br />
                    <span style="font-size:10px;color:grey">{{ item.date_start }}</span> - <span
                        style="font-size:10px;color:grey">{{ item.date_end }}</span>
                </template>

                <!-- <template v-slot:item.actions="{ item }">
                        <v-btn text class="transparent mr-1">BUY <v-icon>mdi-chevron-right</v-icon></v-btn>
                        <v-btn text class="transparent mr-1">SELL <v-icon>mdi-chevron-right</v-icon></v-btn>
                        <v-btn text class="transparent mr-1">TRADE <v-icon>mdi-chevron-right</v-icon></v-btn>
                    </template> -->

            </v-data-table>
            <!-- </v-card> -->
        </v-col>

        <!-- mobile view -->
        <v-col v-else cols="12">
            <!-- check if loading -->
            <div v-if="isLoading" class="text-center">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </div>

            <div v-else>
                <!-- check if empty -->
                <div v-if="!filteredData.length <= 0">
                    <v-row align="center" justify="center" class="py-3"
                        v-for=" { image, name, apr, balance, duration, date_start, date_end }  in  filteredData "
                        style="border-bottom: 0.1px solid #b7bdc6;">

                        <v-col sm="12" align="start">
                            <div class="d-flex align-center justify-space-between">
                                <div>
                                    <v-avatar size="30" class="mr-2">
                                        <v-img :src="image"></v-img>
                                    </v-avatar>
                                    <span style="font-size: 14px; font-weight: 500;"> {{ name }}</span>
                                </div>
                                <span style="font-size: 14px; font-weight: 500;">{{ apr }}% APR</span>
                            </div>

                            <div class=" mt-2 d-flex align-center justify-space-between">
                                <span style="font-size: 14px; color: #b7bdc6;">{{ $t('wallet.balance') }}: </span>
                                <span style="font-size: 14px; font-weight: 500;"> {{ balance }}</span>
                            </div>


                            <div class="mt-2 d-flex align-center justify-space-between">
                                <span style="font-size: 14px; color: #b7bdc6;">{{ $t('wallet.duration') }}: </span>
                                <span style="font-size: 14px; font-weight: 500;" class="ml-2"> {{ duration }}
                                    Day/s</span>
                            </div>

                            <div class="mt-2 d-flex align-center justify-space-between">
                                <span style="font-size: 12px; color: #b7bdc6;">{{ $t('wallet.startDate') }}: </span>
                                <span style="font-size: 12px; font-weight: 500;"> {{ date_start }}</span>
                            </div>

                            <div class="mt-2 d-flex align-center justify-space-between">
                                <span style="font-size: 12px; color: #b7bdc6;">{{ $t('wallet.endDate') }}: </span>
                                <span style="font-size: 12px; font-weight: 500;"> {{ date_end }}</span>
                            </div>

                        </v-col>

                    </v-row>
                </div>

                <div v-else class="d-flex align-center justify-center mt-10" style="height: 100%">
                    <div class="d-flex flex-column align-center justify-center">
                        <v-icon x-large color="#b7bdc6">
                            mdi mdi-note-search-outline
                        </v-icon>
                        <span style="font-size: 12px; color: #b7bdc6">{{ $t('wallet.noRecords') }}</span>

                        <div class="mt-3">
                            <ButtonComponent :onClick="handleSubscribeClick" style="color: #1C1D28 ;" color="primary"
                                label="Subscribe Now" />
                        </div>

                    </div>
                </div>
            </div>
        </v-col>
    </v-row>
</template>

<script>
import ButtonComponent from '@/components/ui/ButtonComponent.vue';
import SearchBarComponent from '@/components/ui/SearchBarComponent.vue';

export default {
    components: {
        ButtonComponent,
        SearchBarComponent,
    },

    data() {

        return {
            earnStaking: [],
            isLoading: false,
            search: '',
            headers: [
                {
                    value: "name",
                    text: "Token",
                    align: "start",
                    sortable: true,
                },

                {
                    value: "apr",
                    text: "Appraisal",
                    sortable: true,
                    align: "start",
                },

                {
                    value: "balance",
                    text: "Balance",
                    sortable: true,
                    align: "start",
                },

                {
                    value: "accumulation",
                    text: "Accumulation",
                    sortable: true,
                    align: "start",
                },

                {
                    value: "Accumulative Interest",
                    text: "",
                    sortable: true,
                    align: "start",
                },

                {
                    value: "duration",
                    text: "Duration",
                    sortable: true,
                    align: "end",
                },
            ],
        }
    },

    mounted() {
        this.fetchAssets()
    },

    methods: {
        fetchAssets() {
            this.isLoading = true
            this.$axios.get('https://api.uccoin.io/api/v1/stake')
                .then(response => {
                    try {
                        this.earnStaking = response.data.earnStaking
                    } catch (error) {
                        console.log(error)
                    } finally {
                        this.isLoading = false
                    }
                })
        },

        handleSubscribeClick() {
            this.$router.push(`/wallet/simple_earn`);
        }
    },

    computed: {
        filteredData() {
            if (!this.search) {
                return this.earnStaking;
            }
            const searchTerm = this.search.toLowerCase();
            return this.earnStaking.filter(item => item.name.toLowerCase().includes(searchTerm));
        },
    }
}
</script>