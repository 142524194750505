<template>
    <v-btn :class="btnClass" :color="color" @click="onClick" :elevation="elevation" :disabled="disabled" :block="block"
        :x-small="isXsmall" :small="small" :large="large" :x-large="isXLarge">
        {{ label }}
    </v-btn>
</template>

<script>
export default {
    props: {
        btnClass: {
            type: String,
            required: false
        },

        label: {
            type: String,
            required: false
        },

        color: {
            type: String,
            required: true,
        },

        isXsmall: {
            type: Boolean,
            required: false
        },

        isXlarge: {
            type: Boolean,
            required: false
        },

        small: {
            type: Boolean,
            required: false
        },

        large: {
            type: Boolean,
            required: false
        },

        elevation: {
            type: String,
            required: false,
        },

        disabled: {
            type: Boolean,
            required: false
        },

        block: {
            type: Boolean,
            required: false
        },

        onClick: {
            type: Function,
            required: false
        }
    }
}
</script>