<template>
  <v-row>
    <h1 class="font-weight-bold">Trending on Feed</h1>
    <p class="grey--text darken-2">
      Discover the latest crypto news and feed from news media and influencers.
    </p>
    <v-col cols="8">
      <v-row>
        <v-col v-for="feed in feeds" :key="feed.id" cols="6">
          <!-- Feeds -->
          <v-card class="transparent elevation-0">
            <v-card-text>
              <v-row>
                <v-col>
                  <v-avatar size="25">
                    <v-img src="https://i.pravatar.cc/300"></v-img>
                  </v-avatar>
                  <span class="ml-2 font-weight-bold">{{ feed.name }}</span>
                  <h6 class="mt-2 white--text font-weight-bold text-truncate">
                    {{ feed.text }}
                  </h6>
                  <span class="caption">{{ feed.time }}</span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="4">
      <v-card shaped>
        <img height="250" src="@/assets/svgs/world.svg" />
        <v-card-text>
          <h4>World's largest crypto community</h4>
        </v-card-text>
        <v-card-actions>
          <v-btn class="ml-2 black--text" color="secondary" x-large to="/signin"
            >Get Started</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data: () => ({
    loading: true,
    //Generate 6 feeds with id, name, text and time. The feeds should be kind of actual
    feeds: [
      {
        id: 1,
        name: "John Doe",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        time: "2 hours ago",
      },
      {
        id: 2,
        name: "Jane Doe",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        time: "2 hours ago",
      },
      {
        id: 3,
        name: "John Doe",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        time: "2 hours ago",
      },
      {
        id: 4,
        name: "Jane Doe",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        time: "2 hours ago",
      },
      {
        id: 5,
        name: "John Doe",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        time: "2 hours ago",
      },
      {
        id: 6,
        name: "Jane Doe",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        time: "2 hours ago",
      },
    ],
  }),
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
  computed: {
    marketCoins() {
      return this.$store.getters.marketCoins;
    },
    filteredCoins() {
      return this.marketCoins.filter((coin) => coin.type === "MAIN");
    },
  },
};
</script>
<style>
#phones {
  max-height: 600px; /* 50% of 280px */
  max-width: 600px; /* 50% of 280px */
  object-fit: contain;
}
.theme--dark.v-tabs-items {
  background-color: transparent !important;
}
</style>
