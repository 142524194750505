<template>
    <v-row>
        <v-col cols="12">
            <!-- <SearchBarComponent /> -->

            <!-- Loading indicator -->
            <!-- <div v-if="isLoading" class="text-center">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </div> -->

            <div class="mt-5" v-if="!$vuetify.breakpoint.xs">
                <v-card class="pa-5" color="#00000000" style="border-radius: 5px; border: 1px solid #b7bdc6 ;">
                    <v-data-table :loading="isLoading" :headers="headers" :items="depositHistory" :items-per-page="20"
                        @click:row="rowClicked($event)" class="transparent">

                        <template v-slot:item.name="{ item }">
                            <div class="py-3 d-flex align-center">
                                <div class="mr-3">
                                    <v-avatar size="30">
                                        <v-img :src="item.image"></v-img>
                                    </v-avatar>
                                </div>

                                <div class="mr-2 d-flex align-center">
                                    <!-- <span class="mr-2" style="font-size:16px;">{{ item.coin_pair_name }}</span><br /> -->
                                    <span class="">{{ (item.name) }}</span>
                                    <!-- <span style="font-size: 14px;"> {{ item.date }}</span><br /> -->
                                </div>
                            </div>
                        </template>


                    </v-data-table>
                </v-card>
            </div>

            <div v-else>
                <div v-if="depositHistory.length <= 0">
                    <div class="d-flex align-center justify-center mt-10" style="height: 100%">
                        <div class="d-flex flex-column align-center justify-center">
                            <v-icon x-large color="#b7bdc6">
                                mdi mdi-note-search-outline
                            </v-icon>
                            <span style="font-size: 12px; color: #b7bdc6">No Records Found</span>
                        </div>
                    </div>
                </div>

                <div v-else>
                    <v-row v-for="{ image, coin, recharge_amount, response_time, recharge_status } in depositHistory"
                        justify="center" class="mt-3 py-2" style="border-bottom: 1px solid #2b3139;">
                        <v-col sm="6" align="start">
                            <div class="d-flex flex-column">

                                <div class="d-flex align-center">
                                    <span style="font-size: 14px; font-weight: 500"> {{ coin }}</span>
                                </div>
                                <span class="mt-1" style="font-size: 14px; font-weight: 500; color: #0ECB81  ;">
                                    {{ recharge_status }}
                                </span>
                                <span class="mt-1" style="font-size: 12px; color: #b7bdc6 ;">{{ response_time }}</span>
                            </div>
                        </v-col>

                        <v-col sm="6" align="end">
                            <div class="d-flex flex-column">
                                <span style="font-size: 14px; font-weight: 500; color: #0ECB81  ;">
                                    {{ recharge_amount }}
                                </span>
                            </div>
                            <!-- <span  style="font-size:10px;color:grey">${{ parseFloat(item.usdt_value).toFixed(2) }}</span> -->
                        </v-col>
                    </v-row>
                </div>
            </div>
        </v-col>
    </v-row>
</template>

<script>

export default {
    props: {
        depositHistory: {
            type: Array,
            required: false,
        },

        coinData: {
            type: Array,
            required: false
        }
    },

    data: () => ({

        headers: [
            {
                value: "coin",
                text: "Asset",
                align: "start",
                sortable: true,
            },

            {
                value: "recharge_amount",
                text: "Amount",
                sortable: true,
                align: "start",
            },

            {
                value: "response_time",
                text: "Date",
                align: "start",
                sortable: true,
            },
            {
                value: "recharge_status",
                text: "status",
                sortable: true,
                align: "center",
            },
        ],
    }),
}

</script>