<template>
    <v-select dense v-model="bindData" :items="items" outlined :rules="rules"
        :menu-props="{ bottom: true, offsetY: true }"></v-select>
</template>

<script>
export default {
    props: {
        bindData: {
            type: [String, Number], // Use | operator instead of &&
            required: false // Make it required if it's essential for the component
        },

        value: {
            type: [String, Number],
            default: ''
        },

        items: {
            type: [Array, Object],
            default: false
        },

        disabled: {
            type: Boolean,
            default: false // Provide default value if needed
        },

        dense: {
            type: Boolean,
            default: false // Provide default value if needed
        },

        rules: {
            type: Array,
            required: false
        },
    },
    data() {
        return {
            localValue: this.bindData // Initialize localValue with the prop's value
        };
    },
    watch: {
        // Watch for changes in bindData and update localValue accordingly
        bindData(newValue) {
            this.localValue = newValue;
        },
        // If localValue changes externally, emit the update to the parent component
        localValue(newValue) {
            this.$emit('update:bindData', newValue);
        }
    }

}
</script>