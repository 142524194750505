import axios from "axios";
import Vue from "vue";
import store from "../store";

Vue.prototype.$axios = axios;

// Add a request interceptor to axios
// Request interceptor
axios.interceptors.request.use((request) => {
    const token = store.getters.token;
    if (token) {
        // Ensure request.headers and request.headers.common exist
        request.headers = request.headers || {};
        request.headers.common = request.headers.common || {};
        
        request.headers.Authorization = `Bearer ${token}`;
    }
    return request;
});