<template>
  <v-row justify="center">
    <v-col cols="5">
      <v-toolbar class="transparent elevation-0">
        <!-- <v-btn icon color="secondary" to="/security">
          <icon
            icon="material-symbols:arrow-back"
            width="24"
            height="24"
          ></icon>
        </v-btn> -->
        <v-spacer></v-spacer>
        <v-toolbar-title class="display-1">{{ $t('verify.verifyAccount') }}</v-toolbar-title>

      </v-toolbar>
      <v-container>
        <div v-if="!loading">

          <v-card class="transparent elevation-0" v-if="status == 'PENDING'">
            <v-card-text class="text-center">
              <h3 class="text-center secondary--text">{{ $t('verify.pendingKyc') }}</h3>
            </v-card-text>
          </v-card>

          <v-stepper v-else-if="status == 'DISSAPROVED' || status == 'NOEXISTINGKYC'" v-model="step"
            class="transparent elevation-0">
            <v-stepper-header class="elevation-0">
              <v-stepper-step :complete="step > 1" step="1" />

              <v-divider></v-divider>

              <v-stepper-step :complete="step > 2" step="2" />

              <v-divider></v-divider>

              <v-stepper-step :complete="step > 3" step="3" />

              <v-divider></v-divider>

              <v-stepper-step step="4" />
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <v-form ref="form" lazy-loading>
                  <label> {{ $t('verify.idType') }}</label>
                  <v-row align="center" class="mb-3">
                    <v-col cols="12">
                      <v-select v-model="idType" :items="idTypes" dense :rules="rules.required"></v-select>
                    </v-col>
                  </v-row>
                  <label>{{ $t('verify.idNumber') }}</label>
                  <v-row align="center">
                    <v-col cols="12">
                      <v-text-field v-model="idNumber" dense :rules="rules.required"></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
                <div class="text-end">
                  <v-btn style="color: #1C1D28 " color="primary" :loading="btnLoading" @click="checkForm()">
                    {{ $t(' verify.continue') }}
                  </v-btn>
                </div>
              </v-stepper-content>

              <v-stepper-content step="2">
                <v-form ref="form2" lazy-loading>
                  <label>{{ status == "DISSAPROVED" ? "Re " : "" }}{{ $t('verify.uploadSelfie') }}</label>
                  <v-row align="center" class="mb-3">
                    <v-col cols="12">
                      <v-file-input v-model="image_name" accept="image/*" placeholder="Selfie Photo"
                        :rules="rules.required" :error-message="errorMessage.photo" @change="convertImage" />
                    </v-col>
                  </v-row>
                </v-form>

                <div class="text-end">
                  <v-btn style="color: #1C1D28 " color="primary" :loading="btnLoading" @click="checkForm()"> {{
                    $t('verify.continue') }}
                  </v-btn>
                </div>
              </v-stepper-content>

              <v-stepper-content step="3">
                <v-form ref="form3" lazy-loading>
                  <label>{{ status == "DISSAPROVED" ? "Re " : "" }} {{ $t('verify.uploadFront') }}</label>
                  <v-row align="center" class="mb-3">
                    <v-col cols="12">
                      <v-file-input v-model="image_name" accept="image/*" placeholder="Front ID Photo"
                        :rules="rules.required" :error-message="errorMessage.photo" @change="convertImage" />
                    </v-col>
                  </v-row>
                </v-form>

                <div class="text-end">
                  <v-btn style="color: #1C1D28 " color="primary" :loading="btnLoading" @click="checkForm()">
                    {{ $t('verify.continue') }}
                  </v-btn>
                </div>
              </v-stepper-content>

              <v-stepper-content step="4">
                <v-form ref="form4" lazy-loading>
                  <label>{{ status == "DISSAPROVED" ? "Re " : "" }}
                    {{ $t('verify.uploadBack') }}
                  </label>
                  <v-row align="center" class="mb-3">
                    <v-col cols="12">
                      <v-file-input v-model="image_name" accept="image/*" placeholder="Back ID Photo"
                        :rules="rules.required" :error-message="errorMessage.photo" @change="convertImage" />
                    </v-col>
                  </v-row>
                </v-form>

                <div class="text-end">
                  <v-btn style="color: #1C1D28 " color="primary" :loading="btnLoading = true" @click="checkForm()">
                    {{ $t('verify.continue') }} </v-btn>
                </div>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>

          
          <!-- <v-card class="transparent elevation-0" v-else-if="status == 'APPROVED'">
            <v-card-text class="text-center">
              <h3 class="text-center greenPrimary--text">
                {{ $t('verify.accountIsVerified') }}
              </h3>
            </v-card-text>
          </v-card> -->

        </div>
        <v-container v-else fluid class="text-center">
          <v-progress-circular indeterminate color="primary" class="mb-0"></v-progress-circular>
        </v-container>
      </v-container>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data: () => ({
    loading: true,
    btnLoading: true,
    step: 1,
    idType: "Driver's License",
    idTypes: ["Driver's License", "Government ID", "Passport, Others"],
    idNumber: "",
    image: null,
    image_name: null,
    status: null,
    statuses: [],
    errorMessage: {
      photo: "Photo is required",
    },
    rules: {
      required: [(v) => !!v || "Field is required"],
    },
  }),
  methods: {
    async checkForm() {
      this.loading = true
      if (this.step == 1) {
        if (this.$refs.form.validate()) {
          this.loading = false
          this.step = 2;
        }
      } else if (this.step == 2) {
        if (this.$refs.form2.validate()) {
          this.loading = false
          this.sendImage("SELFIE");
        }
      } else if (this.step == 3) {
        if (this.$refs.form3.validate()) {
          this.loading = false
          this.sendImage("FRONTID");
        }
      } else {
        if (this.$refs.form4.validate()) {
          this.loading = false
          this.sendImage("BACKID");
        }
      }
    },
    convertImage() {
      try {
        let reader = new FileReader();
        reader.onload = () => {
          this.image = reader.result;
        };
        reader.readAsDataURL(this.image_name);
      } catch (error) {
        this.image_name = null;
        this.image = null;
      }
    },
    sendImage(imageType) {
      this.$axios
        .post("https://api.uccoin.io/api/v2/upload/kyc", {
          id_type: this.idType,
          id_number: this.idNumber,
          img_type: imageType,
          image: this.image,
        })
        .then((response) => {
          this.loading = true
          if (response.data.message == "success") {
            this.loading = false
            this.image = null;
            this.image_name = null;
            if (this.step == 2) {
              if (
                this.statuses[this.step] == "DISSAPROVED" ||
                this.statuses[this.step] == "NULL"
              ) {
                this.step = 3;
              } else if (
                this.statuses[this.step + 1] == "DISSAPROVED" ||
                this.statuses[this.step + 1] == "NULL"
              ) {
                this.step = 4;
              } else {
                this.$swal({
                  title: "Success",
                  text: "Your KYC has been submitted",
                  icon: "success",
                  timer: 3000,
                  showConfirmButton: false,
                });
                this.$router.push("/security");
              }
            } else if (this.step == 3) {
              if (
                this.statuses[this.step] == "DISSAPROVED" ||
                this.statuses[this.step] == "NULL"
              ) {
                this.step = 4;
              } else {
                this.$swal({
                  title: "Success",
                  text: "Your KYC has been submitted",
                  icon: "success",
                  timer: 3000,
                  showConfirmButton: false,
                });
                this.$router.push("/security");
              }
            } else if (this.step == 4) {
              this.$swal({
                title: "Success",
                text: "Your KYC has been submitted",
                icon: "success",
                timer: 3000,
                showConfirmButton: false,
              });
              this.$router.push("/security");
            } else {
              this.$swal({
                title: "Success",
                text: "Your KYC has been submitted",
                icon: "success",
                timer: 3000,
                showConfirmButton: false,
              });
              this.$router.push("/security");
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    checkKYC() {
      this.loading = true;
      this.$axios
        .get("https://api.uccoin.io/api/v2/upload/check-kyc")
        .then((response) => {
          if (response.data.message === "no_existing_kyc") {
            this.statuses[1] = "NULL";
            this.statuses[2] = "NULL";
            this.statuses[3] = "NULL";
            this.status = "NOEXISTINGKYC";
          } else {
            this.statuses[1] = response.data.selfie
              ? response.data.selfie.status
              : "NULL";
            this.statuses[2] = response.data.front
              ? response.data.front.status
              : "NULL";
            this.statuses[3] = response.data.back
              ? response.data.back.status
              : "NULL";
            let idNumber = response.data.id_number;
            let idType = response.data.id_type;
            switch (true) {
              case this.statuses.includes("NULL"):
                this.step = this.statuses.indexOf("NULL") + 1;
                // console.log(this.step);
                this.status = "NOEXISTINGKYC";
                break;
              case this.statuses.includes("PENDING"):
                this.status = "PENDING";
                break;
              case this.statuses.includes("DISSAPROVED"):
                this.status = "DISSAPROVED";
                this.errorMessage.photo =
                  "Your photo is not clear. Please try again.";
                this.step = this.statuses.indexOf("DISSAPROVED") + 1;
                break;
              default:
                this.status = "APPROVED";
            }

            if (idNumber) {
              this.idNumber = idNumber;
            }
            if (idType) {
              this.idType = idType;
            }
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.checkKYC();
  },
};
</script>
<style>
/* Position the logout button at the bottom right corner */
.v-btn--absolute {
  position: fixed;
  bottom: 16px;
  right: 16px;
}

/* Button Spinner */
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
