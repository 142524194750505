import Vue from "vue";
import moment from "moment";

Vue.filter("upText", function (text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
});

//filter event date and time
Vue.filter("myDateTime", function (created) {
    return moment(created).format("ddd, MMM D, h:mm A");
});

Vue.filter("myTime", function (created) {
    return moment(created).format("dddd, h:mm A");
});

Vue.filter("myDate", function (created) {
    return moment(created).format("ddd, MMMM D");
});

Vue.filter("shortDate", function (created) {
    return moment(created).format("MM-DD-YY");
});

Vue.filter("currencyUSD", function (amount) {
    if (amount == null)
        return parseInt(0).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
        });
    else
        return parseInt(amount).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
        });
});

Vue.filter("currencyUSD", function (amount) {
    if (amount == null)
        return parseInt(0).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
        });
    else
        return parseInt(amount).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
        });
});

Vue.filter("commaFormat", function (value) {
    if (!value) return "0";
    return value.toLocaleString();
});

Vue.filter("commaWithDecimal", function (value) {
    if (!value) return "0";
    return value.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
});
