import { render, staticRenderFns } from "./ActionButtons.vue?vue&type=template&id=28018a18&scoped=true"
var script = {}
import style0 from "./ActionButtons.vue?vue&type=style&index=0&id=28018a18&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28018a18",
  null
  
)

export default component.exports