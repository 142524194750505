<template>
    <v-container fluid>
        <v-btn-toggle v-model="marketType" dense>
            <v-btn class="text-capitalize" to="convert" text> Convert </v-btn>
            <v-btn class="text-capitalize" :value="2" text> Spot </v-btn>
        </v-btn-toggle>
        <v-row class="mt-1">
            <v-col class="d-flex align-center">
                <h4
                    class="font-weight-bold mb-0"
                    @click="marketCoinsDialog = true"
                >
                    {{ localMarketCoin.name }}/USDT
                </h4>
                <span class="ml-3 greenPrimary--text">1.15%</span>
            </v-col>
            <v-col class="text-end">
                <v-btn icon class="primary--text" to="chart">
                    <icon
                        icon="material-symbols:candlestick-chart-rounded"
                        width="24"
                        height="24"
                    ></icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-dialog v-model="marketCoinsDialog">
            <v-card>
                <v-card-text>
                    <v-select
                        :items="marketCoins"
                        v-model="localMarketCoin"
                        item-text="name"
                        item-value="id"
                    >
                    </v-select>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
export default {
    props: {
        marketCoin: {
            type: Object,
        },
    },
    data: () => ({
        localMarketCoin: { name: "BTC" },
        marketCoinsDialog: false,
        marketType: 2,
    }),

    computed: {
        marketCoins() {
            return this.$store.getters.marketCoins;
        },
    },

    mounted() {
        this.localMarketCoin = this.marketCoins.find(
            (coin) => coin.name === this.localMarketCoin.name
        );
    },
    beforeDestroy() {},

    methods: {
        updateMarketCoin() {
            this.$emit("update:marketCoin", this.localMarketCoin);
        },
    },
};
</script>
