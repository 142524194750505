<template>
    <v-navigation-drawer style="width: 300px !important;" permanent color="#00000000">

        <!-- <v-list-item>
            <v-list-item-content>
                <v-list-item-title class="text-h6">
                    Application
                </v-list-item-title>
                <v-list-item-subtitle>
                    subtext
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider> -->

        <v-list nav>
            <v-list-item v-for="item in items" :key="item.title" :to="item.path" link>
                <v-list-item-title>
                    <icon class="mr-1" :icon="item.icon" width="24" height="24" />
                    {{ item.title }}
                </v-list-item-title>
            </v-list-item>
        </v-list>

    </v-navigation-drawer>
</template>

<script>
export default {
    data() {
        return {
            items: [
                {
                    title: "Profile",
                    icon: "material-symbols:account-circle",
                    path: "/profile",
                },

                {
                    title: "Fee Rates",
                    icon: "mdi-cash",
                    path: "/fee-rate",
                },

                {
                    title: "Wallet",
                    icon: "mdi-wallet-bifold-outline",
                    path: "/wallet",
                },

                {
                    title: "Rewards Center",
                    icon: "solar:ticket-bold-duotone",
                    path: "/account/rewards",
                },

                {
                    title: "Referral",
                    icon: "solar:user-plus-bold-duotone",
                    path: "/account/referral",
                },
                {
                    title: "Security",
                    icon: "solar:shield-minimalistic-bold-duotone",
                    path: "/account/security",
                },

                {
                    title: "Verification",
                    icon: "mdi-account-check",
                    path: "/verification/overview",
                },

                // {
                //     title: "Settings",
                //     icon: "mdi-cog-outline",
                //     path: "/settings",
                // },
            ],
            right: null,
        }
    },
}
</script>