<template>
    <div>
        <v-card v-if="!$vuetify.breakpoint.xs" class="pa-3" color="#272727" style="border-radius: 10px;">

            <v-card-title class="d-flex align-center justify-space-between">
                <div>
                    <v-avatar size="30">
                        <img :src="image" :alt="name" />
                    </v-avatar>
                    <span class="ml-4">{{ name }}</span>
                </div>

                <div class="d-flex flex-column">
                    <span class="regular greenPrimary--text">{{ apr + "%" }}</span>
                    <span class="subtitle">Est APR</span>
                </div>

            </v-card-title>

            <v-divider style="border: 1px solid #b7bdc6;"></v-divider>
            <!-- 
            <v-card-text>
                <v-row>

                    <label>Duration(Days)</label>

                    <v-item-group class=" mt-3" mandatory v-model="selectedDays">
                        <v-row>
                            <v-col v-for="day in daysOptions" :key="day" cols="3">
                                <v-item :value="day" v-slot="{ active, toggle }">
                                    <v-card :color="active ? 'primary' : ''" class="d-flex align-center" dark height="20"
                                        @click="toggle">
                                        <v-scroll-y-transition>
                                            <div class="flex-grow-1 text-center">
                                                {{ day }}
                                            </div>
                                        </v-scroll-y-transition>
                                    </v-card>
                                </v-item>
                            </v-col>
                        </v-row>
                    </v-item-group>

                </v-row>
            </v-card-text> -->

            <v-card-actions class="mt-3">
                <v-row justify="center" no-gutter>
                    <v-col class="mb-1">
                        <v-btn color="primary" style="color:#2b3139; border-radius: 10px" block
                            @click="openDetails()">Subscribe
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>

        <!-- if mobile -->
        <v-sheet v-if="$vuetify.breakpoint.xs" @click="openDetails()" color="#00000000" class="mt-1 pa-4"
            style="border: 0.1px solid #b7bdc6; border-radius: 10px;">
            <div class="d-flex align-center justify-space-between">
                <div>
                    <v-avatar size="25">
                        <img :src="image" :alt="name" />
                    </v-avatar>

                    <span class="ml-2">{{ name }}</span>
                </div>
                <div class="d-flex flex-column">
                    <span class="value-percent ">{{ apr + "%" }}</span>
                    <span class="subtitle">Est APR</span>
                </div>
            </div>
        </v-sheet>

        <v-dialog persistent v-if="this.apr != 'Not available'" v-model="detailsDialog"
            :fullscreen="$vuetify.breakpoint.smAndDown" width="500" transition="transitionClass">
            <v-card color="#272727">

                <v-toolbar elevation="0" class="px-5">
                    <!-- <v-btn small icon @click="detailsDialog = false">
                        <v-icon>mdi mdi-close</v-icon>
                    </v-btn> -->
                    <v-toolbar-title>{{ name }} Subscribe</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn small icon @click="detailsDialog = false">
                        <v-icon>mdi mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-card-text class="mt-5">
                    <v-form ref="earnForm" lazy-loading>

                        <div class="mb-3">
                            <span>Duration(Days)</span>

                            <v-sheet class="pa-3 mt-1" width="100" style="border-radius: 10px;" outlined>
                                Flexible
                            </v-sheet>
                        </div>

                        <v-item-group mandatory v-model="selectedDays">
                            <v-row>
                                <v-col v-for="day in  daysOptions" :key="day" cols="3">
                                    <v-item :value="day" v-slot="{ active, toggle }">
                                        <v-card :color="active ? 'primary' : '#b7bdc6 '" class="d-flex align-center"
                                            height="30" @click="toggle">
                                            <v-scroll-y-transition>
                                                <div class="flex-grow-1 text-center"
                                                    :style="{ color: active ? '#000000' : '' }" style="font-weight:500; ">
                                                    {{ day }}
                                                </div>
                                            </v-scroll-y-transition>
                                        </v-card>
                                    </v-item>
                                </v-col>
                            </v-row>
                        </v-item-group>

                        <v-row>
                            <v-col class="mt-5" cols="12">

                                <label class="mb-3">Amount</label>

                                <v-text-field placeholder="Enter amount" v-model="toCoinAmount" dense outlined required
                                    :rules="rules()" @keyup="toCoinAmountChanged()">
                                    <template v-slot:append>
                                        <v-btn text small @click="maxAmount()">
                                            Max
                                        </v-btn>
                                    </template>
                                </v-text-field>

                                <div>
                                    <div>

                                        <div>
                                            <span class="sub-title">
                                                Available:
                                            </span>

                                            <span style="font-size: 14px; font-weight: 500;" class="white--text">
                                                {{ balance }}
                                                {{ name }}
                                            </span>
                                        </div>

                                        <span class="sub-title1 mb-0 grey--text">
                                            Amount Limit
                                        </span>

                                        <div>
                                            <span class="sub-title">
                                                Minimum:
                                            </span>

                                            <span style="font-size: 14px; font-weight: 500;" class="white--text">
                                                {{ minimum }} {{ name }}
                                            </span>
                                        </div>

                                        <div>
                                            <span class="sub-title">
                                                Maximum:
                                            </span>

                                            <span style="font-size: 14px; font-weight: 500;" class="white--text">
                                                {{ maximum }} {{ name }}
                                            </span>
                                        </div>

                                        <div>
                                            <span class="sub-title">
                                                Total Personal Quota:
                                            </span>

                                            <span style="font-size: 14px; font-weight: 500;" class="white--text">
                                                {{ totalQuota }} {{ name }}
                                            </span>
                                        </div>

                                    </div>

                                    <div class="mt-2">
                                        <label class="h6">Summary</label>
                                        <v-sheet class="pa-3" style="border-radius:10px;" outlined>
                                            <div class="d-flex justify-content-between">
                                                <span>Real-Time APR</span>
                                                <span>
                                                    {{ apr }} ~ {{ apr * 4 }}%
                                                </span>
                                            </div>

                                            <div class="d-flex justify-content-between">
                                                <span class="h6">Est. Daily Rewards</span>
                                                <span class="h6 greenPrimary--text">
                                                    --
                                                </span>
                                            </div>

                                            <span class="caption">
                                                *APR Does not mean the actual or
                                                predicted returns in fiat currency
                                            </span>
                                        </v-sheet>
                                    </div>

                                </div>
                            </v-col>

                            <v-col cols="6">
                                <v-btn class="black--text" block large color="primary" @click="invest('FLEXI_LOCK')">Flexi
                                    lock
                                </v-btn>
                            </v-col>

                            <v-col cols="6">
                                <v-btn block large color="secondary" @click="invest('STAKE_LOCK')">Confirm
                                </v-btn>
                            </v-col>

                        </v-row>
                    </v-form>
                </v-card-text>

            </v-card>
        </v-dialog>

        <!-- <v-dialog persistent v-if="this.apr != 'Not available'" transition="transitionClass" width="500" height="auto"
            v-model="detailsDialog" :fullscreen="$vuetify.breakpoint.smAndDown">

            <v-card>

                <v-toolbar elevation="mt-5" class="px-5">
                    <v-btn small icon @click="detailsDialog = false">
                        <v-icon>mdi mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{ name }} Subscribe</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>

                <v-card-text class="mt-3">
                    <v-form ref="earnForm" lazy-loading>

                        <div class="mb-3">
                            <span>Duration(Days)</span>

                            <v-sheet class="pa-3 mt-1" width="100" style="border-radius: 10px;" outlined>
                                Flexible
                            </v-sheet>
                        </div>

                        <v-item-group mandatory v-model="selectedDays">
                            <v-row>
                                <v-col v-for="day in  daysOptions " :key="day" cols="3">
                                    <v-item :value="day" v-slot="{ active, toggle }">
                                        <v-card :color="active ? 'primary' : '#b7bdc6 '" class="d-flex align-center"
                                            height="30" @click="toggle">
                                            <v-scroll-y-transition>
                                                <div class="flex-grow-1 text-center"
                                                    :style="{ color: active ? '#000000' : '' }" style="font-weight:500; ">
                                                    {{ day }}
                                                </div>
                                            </v-scroll-y-transition>
                                        </v-card>
                                    </v-item>
                                </v-col>
                            </v-row>
                        </v-item-group>

                        <v-row>
                            <v-col class="mt-5" cols="12">
                                <v-text-field v-model="toCoinAmount" dense outlined required :rules="rules()"
                                    @keyup="toCoinAmountChanged()">
                                    <template v-slot:append>
                                        <v-btn text small @click="maxAmount()">
                                            Max
                                        </v-btn>
                                    </template>
                                </v-text-field>


                                <div>
                                    <div>

                                        <div>
                                            <span class="sub-title">
                                                Available:
                                            </span>

                                            <span style="font-size: 14px; font-weight: 500;" class="white--text">
                                                {{ balance }}
                                                {{ name }}
                                            </span>
                                        </div>

                                        <span class="sub-title1 mb-0 grey--text">
                                            Amount Limit
                                        </span>

                                        <div>
                                            <span class="sub-title">
                                                Minimum:
                                            </span>

                                            <span style="font-size: 14px; font-weight: 500;" class="white--text">
                                                {{ minimum }} {{ name }}
                                            </span>
                                        </div>

                                        <div>
                                            <span class="sub-title">
                                                Maximum:
                                            </span>

                                            <span style="font-size: 14px; font-weight: 500;" class="white--text">
                                                {{ maximum }} {{ name }}
                                            </span>
                                        </div>

                                        <div>
                                            <span class="sub-title">
                                                Total Personal Quota:
                                            </span>

                                            <span style="font-size: 14px; font-weight: 500;" class="white--text">
                                                {{ totalQuota }} {{ name }}
                                            </span>
                                        </div>

                                    </div>

                                    <div class="mt-2">
                                        <label class="h6">Summary</label>
                                        <v-sheet>
                                            <div class="d-flex justify-content-between">
                                                <p>Real-Time APR</p>
                                                <p>
                                                    {{ apr }} ~ {{ apr * 4 }}%
                                                </p>
                                            </div>

                                            <div class="d-flex justify-content-between">
                                                <p class="h6">Est. Daily Rewards</p>
                                                <p class="h6 greenPrimary--text">
                                                    --
                                                </p>
                                            </div>

                                            <p class="caption">
                                                *APR Does not mean the actual or
                                                predicted returns in fiat currency
                                            </p>
                                        </v-sheet>
                                    </div>

                                </div>

                            </v-col>

                        </v-row>
                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-row justify="center" no-gutter>
                        <v-col class="pr-1 ml-2 mb-1"><v-btn block large color="primary" @click="invest('FLEXI_LOCK')">Flexi
                                lock
                            </v-btn></v-col>
                        <v-col class="pl-1 mr-2 mb-1"><v-btn class="black--text" block large color="secondary"
                                @click="invest('STAKE_LOCK')">CONFIRM
                            </v-btn></v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog> -->

    </div>
</template>
<script>
export default {
    props: {
        id: String,
        name: String,
        image: String,
        apr: String,
        earn: Array,
    },
    data() {
        return {
            detailsDialog: false,
            selectedDays: 5,
            daysOptions: ["Flexible", 5, 10, 15, 30, 60, 120, 180],
            amount: 0,
            balance: 0,
            totalQuota: 0,
        };
    },
    computed: {
        percentColor() {
            //Return the color based on the percent value if positive or negative
            return this.apr != "Not available"
                ? "percent-card greenPrimary--text d-flex align-center justify-center transparent elevation-0"
                : "percent-card redPrimary--text d-flex align-center justify-center transparent elevation-0";
        },
        percentWithSign() {
            //Return the percent value with a sign
            return this.apr != "Not available"
                ? "APR " + this.apr + "%"
                : "Not Available";
        },
        minimum() {
            return parseFloat(this.earn[0].minimum_amount);
        },
        maximum() {
            return parseFloat(this.earn[0].maximum_amount);
        },

        transitionClass() {
            return $vuetify.breakpoint.smAndDown ? 'dialog-bottom-transition' : '';
        },

    },
    methods: {
        rules() {
            return [
                (v) => !!v || "Amount is required",
                (v) =>
                    v >= this.minimum ||
                    "Amount must be greater than or equal minimum",
                (v) => v <= this.maximum || "Amount must be less than maximum",
                (v) =>
                    v <= this.balance ||
                    "Amount must be less than or equal balance",
            ];
        },
        maxAmount() {
            this.amount = this.balance;
        },
        openDetails() {
            this.$axios
                .get("https://api.uccoin.io/api/v1/wallet-balance", {
                    params: {
                        name: this.name,
                    },
                })
                .then((response) => {
                    this.balance = response.data.wallet.wallet_balance;
                    if (this.apr != "Not available") this.detailsDialog = true;
                });
        },
        invest(type) {
            if (this.$refs.earnForm.validate()) {
                this.$axios
                    .post("https://api.uccoin.io/api/v2/earn/store", {
                        earn_id: this.earn[0].id,
                        days: this.selectedDays,
                        amount: this.amount,
                        status: type,
                    })
                    .then((response) => {
                        if (response.data.message == "success") {
                            this.$swal({
                                title: "Success",
                                text: "Investment successful",
                                icon: "success",
                                timer: 3000,
                                buttons: false,
                            });
                            this.$router.push("/home");
                        } else {
                            this.$swal({
                                title: "Error",
                                text: "Something went wrong",
                                icon: "error",
                                timer: 3000,
                                buttons: false,
                            });
                        }
                    });
            }
        },
    },
};
</script>
<style>
.percent-card {
    font-weight: 550;
}

.regular {
    font-size: 1rem;
    font-weight: 500
}

.subtitle {
    font-size: 12px;
}
</style>
