<template>
    <div v-if="isLoading">
        Loading...
    </div>

    <v-row v-else class="">

        <v-col cols=" 12">
            <div class="d-flex align-center justify-space-between">
                <div align="end" class="item" style=" font-size: 12px; color: #848e9c;">{{ $t('trade.amount') }}({{
                    selectedCoin }})</div>
                <div align="start" class="item" style=" font-size: 12px; color: #848e9c;">{{ $t('trade.price') }}(USDT)
                </div>
                <!-- <div align="end" class="item text-end" style=" font-size: 12px; color: #848e9c;">Total</div> -->
            </div>
        </v-col>

        <ul class="mt-5" style="list-style: none; padding-left: none; display: flex; flex-direction: column-reverse;">
            <li style="padding: 0;" class="mb-1" v-for="(ask, index) in orderBookWithTotals.asks.slice(0, 1)"
                :key="'ask-' + index + '-1'">
                <div style="position: relative;" class="d-flex justify-space-between">
                    <div>{{ formattedAmount(ask[1]) }}</div>
                    <div> <span style="color: #CA3F64; font-size: 14px;">{{ formattedPrice(price) }}</span></div>
                    <div class="highlight-asks d-flex justify-space-between" :style="{ width: ask.totalPercent + '%' }">
                    </div>
                </div>
            </li>
            <li style="padding: 0;" class="mb-1" v-for="(ask, index) in orderBookWithTotals.asks.slice(0, 1)"
                :key="'ask-' + index + '-2'">
                <div style="position: relative;" class="d-flex justify-space-between">
                    <div>{{ formattedAmount(ask[1]) }}</div>
                    <div> <span style="color: #CA3F64; font-size: 14px;">{{ formattedPrice(price) }}</span></div>
                    <div class="highlight-asks d-flex justify-space-between" :style="{ width: (ask.totalPercent) + '%' }">
                    </div>
                </div>
            </li>
            <li style="padding: 0;" class="mb-1" v-for="(ask, index) in orderBookWithTotals.asks.slice(0, 1)"
                :key="'ask-' + index + '-3'">
                <div style=" position: relative;" class="d-flex justify-space-between">
                    <div>{{ formattedAmount(ask[1]) }}</div>
                    <div> <span style="color: #CA3F64; font-size: 14px;">{{ formattedPrice(price) }}</span></div>
                    <div class="highlight-asks d-flex justify-space-between" :style="{ width: ask.totalPercent + '%' }">
                    </div>
                </div>
            </li>

            <!-- 2 -->
            <li style="padding: 0;" class="mb-1" v-for="(ask, index) in orderBookWithTotals.asks.slice(1, 2)"
                :key="'ask-' + index + '-4'">
                <div style="position: relative;" class="d-flex justify-space-between">
                    <div>{{ formattedAmount(ask[1]) }}</div>
                    <div> <span style="color: #CA3F64; font-size: 14px;">{{ formattedPrice(price) }}</span></div>
                    <div class="highlight-asks d-flex justify-space-between" :style="{ width: (ask.totalPercent) + '%' }">
                    </div>
                </div>
            </li>
            <li style="padding: 0;" class="mb-1" v-for="(ask, index) in orderBookWithTotals.asks.slice(1, 2)"
                :key="'ask-' + index + '-5'">
                <div style="position: relative;" class="d-flex justify-space-between">
                    <div>{{ formattedAmount(ask[1]) }}</div>
                    <div> <span style="color: #CA3F64; font-size: 14px;">{{ formattedPrice(price) }}</span></div>
                    <div class="highlight-asks d-flex justify-space-between" :style="{ width: (ask.totalPercent) + '%' }">
                    </div>
                </div>
            </li>
            <li style="padding: 0;" class="mb-1" v-for="(ask, index) in orderBookWithTotals.asks.slice(1, 2)"
                :key="'ask-' + index + '-6'">
                <div style=" position: relative;" class="d-flex justify-space-between">
                    <div>{{ formattedAmount(ask[1]) }}</div>
                    <div> <span style="color: #CA3F64; font-size: 14px;">{{ formattedPrice(price) }}</span></div>
                    <div class="highlight-asks d-flex justify-space-between" :style="{ width: (ask.totalPercent) + '%' }">
                    </div>
                </div>
            </li>

            <!-- 3 -->
            <li style="padding: 0;" class="mb-1" v-for="(ask, index) in orderBookWithTotals.asks.slice(2, 3)"
                :key="'ask-' + index + '-7'">
                <div style="position: relative;" class="d-flex justify-space-between">
                    <div>{{ formattedAmount(ask[1]) }}</div>
                    <div> <span style="color: #CA3F64; font-size: 14px;">{{ formattedPrice(price) }}</span></div>
                    <div class="highlight-asks d-flex justify-space-between" :style="{ width: (ask.totalPercent) + '%' }">
                    </div>
                </div>
            </li>
            <li style="padding: 0;" class="mb-1" v-for="(ask, index) in orderBookWithTotals.asks.slice(2, 3)"
                :key="'ask-' + index + '-8'">
                <div style="position: relative;" class="d-flex justify-space-between">
                    <div>{{ formattedAmount(ask[1]) }}</div>
                    <div> <span style="color: #CA3F64; font-size: 14px;">{{ formattedPrice(price) }}</span></div>
                    <div class="highlight-asks d-flex justify-space-between" :style="{ width: (ask.totalPercent) + '%' }">
                    </div>
                </div>
            </li>
            <li style="padding: 0;" class="mb-1" v-for="(ask, index) in orderBookWithTotals.asks.slice(2, 3)"
                :key="'ask-' + index + '-9'">
                <div style=" position: relative;" class="d-flex justify-space-between">
                    <div>{{ formattedAmount(ask[1]) }}</div>
                    <div> <span style="color: #CA3F64; font-size: 14px;">{{ formattedPrice(price) }}</span></div>
                    <div class="highlight-asks d-flex justify-space-between" :style="{ width: (ask.totalPercent) + '%' }">
                    </div>
                </div>
            </li>


            <!-- 4 -->
            <li style="padding: 0;" class="mb-1" v-for="(ask, index) in  orderBookWithTotals.asks.slice(3, 4) "
                :key="'ask-' + index + '-10'">
                <div style="position: relative;" class="d-flex justify-space-between">
                    <div>{{ formattedAmount(ask[1]) }}</div>
                    <div> <span style="color: #CA3F64; font-size: 14px;">{{ formattedPrice(price) }}</span></div>
                    <div class="highlight-asks d-flex justify-space-between" :style="{ width: (ask.totalPercent) + '%' }">
                    </div>
                </div>
            </li>
            <li style="padding: 0;" class="mb-1" v-for="( ask, index ) in  orderBookWithTotals.asks.slice(3, 4) "
                :key="'ask-' + index + '-11'">
                <div style="position: relative;" class="d-flex justify-space-between">
                    <div>{{ formattedAmount(ask[1]) }}</div>
                    <div> <span style="color: #CA3F64; font-size: 14px;">{{ formattedPrice(price) }}</span></div>
                    <div class="highlight-asks d-flex justify-space-between" :style="{ width: (ask.totalPercent) + '%' }">
                    </div>
                </div>
            </li>
            <li style="padding: 0;" class="mb-1" v-for="( ask, index ) in  orderBookWithTotals.asks.slice(3, 4) "
                :key="'ask-' + index + '-12'">
                <div style="position: relative;" class="d-flex justify-space-between">
                    <div>{{ formattedAmount(ask[1]) }}</div>
                    <div> <span style="color: #CA3F64; font-size: 14px;">{{ formattedPrice(price) }}</span></div>
                    <div class="highlight-asks d-flex justify-space-between" :style="{ width: (ask.totalPercent) + '%' }">
                    </div>
                </div>
            </li>
            <li style="padding: 0;" class="mb-1" v-for="( ask, index ) in  orderBookWithTotals.asks.slice(3, 4) "
                :key="'ask-' + index + '-13'">
                <div style="position: relative;" class="d-flex justify-space-between">
                    <div>{{ formattedAmount(ask[1]) }}</div>
                    <div> <span style="color: #CA3F64; font-size: 14px;">{{ formattedPrice(price) }}</span></div>
                    <div class="highlight-asks d-flex justify-space-between" :style="{ width: (ask.totalPercent) + '%' }">
                    </div>
                </div>
            </li>

            <!-- 5 -->
            <!-- <li style="padding: 0;" class="mb-1" v-for="(ask, index) in orderBookWithTotals.asks.slice(4, 5)" :key="'ask-' + index + '-10'">
                <div style="position: relative;" class="d-flex justify-space-between">
                    <div>{{ formattedAmount(ask[1]) }}</div>
                    <div> <span style="color: #CA3F64; font-size: 14px;">{{ formattedPrice(price) }}</span></div>
                    <div class="highlight-asks d-flex justify-space-between"
                        :style="{ width: (ask.totalPercent + (ask.totalPercent)) + '%' }">
                    </div>
                </div>
            </li> -->
            <!-- <li style="padding: 0;" class="mb-1" v-for="(ask, index) in orderBookWithTotals.asks.slice(4, 5)" :key="index">
                <div style="position: relative;" class="d-flex justify-space-between">
                    <div>{{ formattedAmount(ask[1]) }}</div>
                    <div> <span style="color: #CA3F64; font-size: 14px;">{{ formattedPrice(price) }}</span></div>
                    <div class="highlight-asks d-flex justify-space-between"
                        :style="{ width: (ask.totalPercent + (ask.totalPercent)) + '%' }">
                    </div>
                </div>
            </li> -->

            <li style="padding: 0;" class="mb-1" v-for="( ask, index ) in  orderBookWithTotals.asks.slice(4, 5) "
                :key="'ask-' + index + '-14'">
                <div style="position: relative;" class="d-flex justify-space-between">
                    <div>{{ formattedAmount(ask[1]) }}</div>
                    <div> <span style="color: #CA3F64; font-size: 14px;">{{ formattedPrice(price) }}</span></div>
                    <div class="highlight-asks d-flex justify-space-between" :style="{ width: (ask.totalPercent) + '%' }">
                    </div>
                </div>
            </li>
            <li style="padding: 0;" class="mb-1" v-for="( ask, index ) in  orderBookWithTotals.asks.slice(4, 5) "
                :key="'ask-' + index + '-15'">
                <div style="position: relative;" class="d-flex justify-space-between">
                    <div>{{ formattedAmount(ask[1]) }}</div>
                    <div> <span style="color: #CA3F64; font-size: 14px;">{{ formattedPrice(price) }}</span></div>
                    <div class="highlight-asks d-flex justify-space-between" :style="{ width: (ask.totalPercent) + '%' }">
                    </div>
                </div>
            </li>
            <li style="padding: 0;" class="mb-1" v-for="( ask, index ) in  orderBookWithTotals.asks.slice(4, 5) "
                :key="'ask-' + index + '-16'">
                <div style="position: relative;" class="d-flex justify-space-between">
                    <div>{{ formattedAmount(ask[1]) }}</div>
                    <div> <span style="color: #CA3F64; font-size: 14px;">{{ formattedPrice(price) }}</span></div>
                    <div class="highlight-asks d-flex justify-space-between" :style="{ width: (ask.totalPercent) + '%' }">
                    </div>
                </div>
            </li>

        </ul>

        <div class="mb-5" v-if="price !== null" style="color:#25A750; font-size: 20px; font-weight: 500;">
            {{ formattedPrice(price) }}
        </div>

        <ul style="list-style: none; padding-left: none;">
            <li class="mb-1" v-for="( bid, index ) in  orderBookWithTotals.bids.slice(0, 1) " :key="'bid-' + index + '-1'">
                <div style=" position: relative;" class="d-flex justify-space-between">
                    <div>{{ formattedAmount(bid[1]) }}</div>
                    <div> <span style="color: #25A750; font-size: 14px;">{{ formattedPrice(price) }}</span></div>
                    <div class="highlight-bids d-flex justify-space-between" :style="{ width: bid.totalPercent + '%' }">
                    </div>
                </div>
            </li>
            <li class="mb-1" v-for="( bid, index ) in  orderBookWithTotals.bids.slice(0, 1) " :key="'bid-' + index + '-2'">
                <div style="position: relative;" class="d-flex justify-space-between">
                    <div>{{ formattedAmount(bid[1]) }}</div>
                    <div> <span style="color: #25A750; font-size: 14px;">{{ formattedPrice(price) }}</span></div>
                    <div class="highlight-bids d-flex justify-space-between" :style="{ width: bid.totalPercent + '%' }">
                    </div>
                </div>
            </li>
            <li class="mb-1" v-for="( bid, index ) in  orderBookWithTotals.bids.slice(0, 1) " :key="'bid-' + index + '-3'">
                <div style="position: relative;" class="d-flex justify-space-between">
                    <div>{{ formattedAmount(bid[1]) }}</div>
                    <div> <span style="color: #25A750; font-size: 14px;">{{ formattedPrice(price) }}</span></div>
                    <div class="highlight-bids d-flex justify-space-between" :style="{ width: bid.totalPercent + '%' }">
                    </div>
                </div>
            </li>
            <!-- 2 -->
            <li class="mb-1" v-for="( bid, index ) in  orderBookWithTotals.bids.slice(1, 2) " :key="'bid-' + index + '-4'">
                <div style="position: relative;" class="d-flex justify-space-between">
                    <div>{{ formattedAmount(bid[1]) }}</div>
                    <div> <span style="color: #25A750; font-size: 14px;">{{ formattedPrice(price) }}</span></div>
                    <div class="highlight-bids d-flex justify-space-between" :style="{ width: bid.totalPercent + '%' }">
                    </div>
                </div>
            </li>
            <li class="mb-1" v-for="( bid, index ) in  orderBookWithTotals.bids.slice(1, 2) " :key="'bid-' + index + '-5'">
                <div style="position: relative;" class="d-flex justify-space-between">
                    <div>{{ formattedAmount(bid[1]) }}</div>
                    <div> <span style="color: #25A750; font-size: 14px;">{{ formattedPrice(price) }}</span></div>
                    <div class="highlight-bids d-flex justify-space-between" :style="{ width: bid.totalPercent + '%' }">
                    </div>
                </div>
            </li>
            <li class="mb-1" v-for="( bid, index ) in  orderBookWithTotals.bids.slice(1, 2) " :key="'bid-' + index + '-6'">
                <div style="position: relative;" class="d-flex justify-space-between">
                    <div>{{ formattedAmount(bid[1]) }}</div>
                    <div> <span style="color: #25A750; font-size: 14px;">{{ formattedPrice(price) }}</span></div>
                    <div class="highlight-bids d-flex justify-space-between" :style="{ width: bid.totalPercent + '%' }">
                    </div>
                </div>
            </li>
            <!-- 3 -->
            <li class="mb-1" v-for="( bid, index ) in  orderBookWithTotals.bids.slice(2, 3) " :key="'bid-' + index + '-7'">
                <div style="position: relative;" class="d-flex justify-space-between">
                    <div>{{ formattedAmount(bid[1]) }}</div>
                    <div> <span style="color: #25A750; font-size: 14px;">{{ formattedPrice(price) }}</span></div>
                    <div class="highlight-bids d-flex justify-space-between" :style="{ width: bid.totalPercent + '%' }">
                    </div>
                </div>
            </li>
            <li class="mb-1" v-for="( bid, index ) in  orderBookWithTotals.bids.slice(2, 3) " :key="'bid-' + index + '-8'">
                <div style="position: relative;" class="d-flex justify-space-between">
                    <div>{{ formattedAmount(bid[1]) }}</div>
                    <div> <span style="color: #25A750; font-size: 14px;">{{ formattedPrice(price) }}</span></div>
                    <div class="highlight-bids d-flex justify-space-between" :style="{ width: bid.totalPercent + '%' }">
                    </div>
                </div>
            </li>
            <li class="mb-1" v-for="( bid, index ) in  orderBookWithTotals.bids.slice(2, 3) " :key="'bid-' + index + '-9'">
                <div style="position: relative;" class="d-flex justify-space-between">
                    <div>{{ formattedAmount(bid[1]) }}</div>
                    <div> <span style="color: #25A750; font-size: 14px;">{{ formattedPrice(price) }}</span></div>
                    <div class="highlight-bids d-flex justify-space-between" :style="{ width: bid.totalPercent + '%' }">
                    </div>
                </div>
            </li>
            <!-- 4 -->
            <li class="mb-1" v-for="( bid, index ) in  orderBookWithTotals.bids.slice(3, 4) " :key="'bid-' + index + '-10'">
                <div style="position: relative;" class="d-flex justify-space-between">
                    <div>{{ formattedAmount(bid[1]) }}</div>
                    <div> <span style="color: #25A750; font-size: 14px;">{{ formattedPrice(price) }}</span></div>
                    <div class="highlight-bids d-flex justify-space-between" :style="{ width: bid.totalPercent + '%' }">
                    </div>
                </div>
            </li>
            <li class="mb-1" v-for="( bid, index ) in  orderBookWithTotals.bids.slice(3, 4) " :key="'bid-' + index + '-11'">
                <div style="position: relative;" class="d-flex justify-space-between">
                    <div>{{ formattedAmount(bid[1]) }}</div>
                    <div> <span style="color: #25A750; font-size: 14px;">{{ formattedPrice(price) }}</span></div>
                    <div class="highlight-bids d-flex justify-space-between" :style="{ width: bid.totalPercent + '%' }">
                    </div>
                </div>
            </li>
            <li class="mb-1" v-for="( bid, index ) in  orderBookWithTotals.bids.slice(3, 4) " :key="'bid-' + index + '-12'">
                <div style="position: relative;" class="d-flex justify-space-between">
                    <div>{{ formattedAmount(bid[1]) }}</div>
                    <div> <span style="color: #25A750; font-size: 14px;">{{ formattedPrice(price) }}</span></div>
                    <div class="highlight-bids d-flex justify-space-between" :style="{ width: bid.totalPercent + '%' }">
                    </div>
                </div>
            </li>
            <!-- 5 -->
            <li class="mb-1" v-for="( bid, index ) in  orderBookWithTotals.bids.slice(4, 5) " :key="'bid-' + index + '-13'">
                <div style="position: relative;" class="d-flex justify-space-between">
                    <div>{{ formattedAmount(bid[1]) }}</div>
                    <div> <span style="color: #25A750; font-size: 14px;">{{ formattedPrice(price) }}</span></div>
                    <div class="highlight-bids d-flex justify-space-between" :style="{ width: bid.totalPercent + '%' }">
                    </div>
                </div>
            </li>
            <li class="mb-1" v-for="( bid, index ) in  orderBookWithTotals.bids.slice(4, 5) " :key="'bid-' + index + '-14'">
                <div style="position: relative;" class="d-flex justify-space-between">
                    <div>{{ formattedAmount(bid[1]) }}</div>
                    <div> <span style="color: #25A750; font-size: 14px;">{{ formattedPrice(price) }}</span></div>
                    <div class="highlight-bids d-flex justify-space-between" :style="{ width: bid.totalPercent + '%' }">
                    </div>
                </div>
            </li>
            <li class="mb-1" v-for="( bid, index ) in  orderBookWithTotals.bids.slice(4, 5) " :key="'bid-' + index + '-15'">
                <div style="position: relative;" class="d-flex justify-space-between">
                    <div>{{ formattedAmount(bid[1]) }}</div>
                    <div> <span style="color: #25A750; font-size: 14px;">{{ formattedPrice(price) }}</span></div>
                    <div class="highlight-bids d-flex justify-space-between" :style="{ width: bid.totalPercent + '%' }">
                    </div>
                </div>
            </li>
        </ul>
    </v-row>
</template>

<script>

import numeral from 'numeral'

export default {
    name: "orderBook",
    props: {
        selectedCoin: {
            type: String,
            required: true,
        },
        price: {
            type: [Number, String],
            required: true
        },
        asks: {
            type: Array, // Adjusted to accept only array type
            default: undefined, // Default value when not provided
        },
        bids: {
            type: Array, // Adjusted to accept only array type
            default: undefined, // Default value when not provided
        },
        isLoading: {
            type: Boolean,
            required: false
        }
    },

    data() {
        return {
            prevAsks: [],
            prevBids: [],
            historicalDataLocal: []
            // totalPercent: 50,
        };
    },

    computed: {
        formattedPrice() {
            return (price) => numeral(price).format('0,0.0');
        },
        formattedOrders() {
            return (order) => numeral(order).format('0.0');
        },
        formattedAmount() {
            return (order) => numeral(order).format('0.00000');
        },
        orders() {
            return this.$store.state.orders;
        },

        getHistoricalData() {
            const historicalDataString = localStorage.getItem("historicalData");

            // Check if historicalDataString is not null or undefined before parsing
            if (historicalDataString) {
                const historicalDataLocal = JSON.parse(historicalDataString);
                this.historicalData = historicalDataLocal;
                this.price = this.historicalData.bars[5000].close
                this.high = this.historicalData.bars[5000].high
                this.low = this.historicalData.bars[5000].low
                this.volume = this.historicalData.bars[5000].volume
                // Now historicalData contains the parsed data from localStorage
            } else {
                // Handle the case when historicalDataString is null or undefined
            }

        },
        orderBookWithTotals() {
            const asks = this.asks || [];
            const bids = this.bids || [];

            const totalAsk = asks.reduce((prev, cur) => prev + parseFloat(cur[1]), 0);
            const totalBid = bids.reduce((prev, cur) => prev + parseFloat(cur[1]), 0);

            const asksWithTotals = asks.map((ask, idx) => {
                const cumulativeTotal = asks.slice(0, idx + 1).reduce((acc, cur) => acc + parseFloat(cur[1]), 0);
                const totalPercent = totalAsk !== 0 ? (cumulativeTotal / totalAsk) * 100 : 0;
                return { ...ask, total: cumulativeTotal, totalPercent };
            });

            const bidsWithTotals = bids.map((bid, idx) => {
                const cumulativeTotal = bids.slice(0, idx + 1).reduce((acc, cur) => acc + parseFloat(cur[1]), 0);
                const totalPercent = totalBid !== 0 ? (cumulativeTotal / totalBid) * 100 : 0;
                return { ...bid, total: cumulativeTotal, totalPercent };
            });

            return {
                asks: asksWithTotals || [],
                bids: bidsWithTotals || [],
            };
        },

    },

}
</script>

<style scoped>
@keyframes colorChange {
    100% {
        opacity: 1;
    }
}

.highlight-bids,
.highlight-asks {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    animation: colorChange 2s infinite ease-out;
    /* Apply animation with ease-out effect */
}

.highlight-bids {
    background: rgba(14, 203, 129, 0.2);
}

.highlight-asks {
    background: rgba(246, 70, 93, 0.2);

}
</style>

