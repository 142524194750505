<template>
  <v-row justify="space-between" align="center">
    <v-col>
      <h6 class="caption">Total Balance(USDT)</h6>
      <h2 v-if="!loading" class="font-weight-bold">
        {{ balance | commaFormat }}
      </h2>
      <v-progress-circular
        v-else
        indeterminate
        color="secondary"
        class="custom-loader"
      ></v-progress-circular>
    </v-col>
    <v-col class="text-right">
      <v-btn to="deposit" color="secondary" class="white--text" rounded
        >Deposit</v-btn
      >
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    loading: true,
  }),
  computed: {
    balance() {
      return this.$store.state.wallet.usdtWallet[0].usdtWallet;
    },
  },
  methods: {
    async fetchWallet() {
      await this.$store.dispatch("setWallet");
      this.loading = false;
    },
  },
  mounted() {
    this.fetchWallet();
  },
};
</script>
