<template>
    <v-text-field :value="value" :v-model="bindData" outlined :dense="dense" :disabled="disabled"
        :placeholder="placeholder.toString()" :rules="rules"></v-text-field>
</template>
  
<script>
export default {
    props: {
        bindData: {
            type: [String, Number], // Use | operator instead of &&
            required: false // Make it required if it's essential for the component
        },

        value: {
            type: [String, Number],
            default: ''
        },

        placeholder: {
            type: [String, Number],
            default: '' // Provide default value if needed
        },

        disabled: {
            type: Boolean,
            default: false // Provide default value if needed
        },

        dense: {
            type: Boolean,
            default: false // Provide default value if needed
        },

        rules: {
            type: Array,
            required: false
        },
    },
    data() {
        return {
            internalValue: this.bindData // Use internalValue to track changes locally
        };
    },
    watch: {
        internalValue(newVal) {
            this.$emit('update:bindData', newVal); // Emit event to update bindData in parent component
        }
    }
};
</script>