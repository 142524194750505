<template>
  <v-container>

    <v-row justify="center">
      <v-col cols="12" xs="12" lg="9">
        <v-tabs v-model="activeTab" background-color="#00000000" bg-color="transparent">
          <v-tab @click="navigateToTab('/markets/prices')" value="prices">All</v-tab>
          <v-tab @click="navigateToTab('/markets/pos')" value="pos">POS</v-tab>
          <v-tab @click="navigateToTab('/markets/pow')" value="pow">POW</v-tab>
          <v-tab @click="navigateToTab('/markets/innovation')" value="innovation">INNOVATION</v-tab>
          <v-tab @click="navigateToTab('/markets/favorites')" value="favorites">FAVORITES</v-tab>
        </v-tabs>
        <router-view></router-view>
      </v-col>
    </v-row>

  </v-container>
</template>
<script>

export default {

  data() {
    return {
      activeTab: null,
    }
  },

  created() {
    // Set the active tab on component creation
    this.activeTab = this.$route.path;
  },

  methods: {
    navigateToTab(tab) {
      if (this.$route.path !== tab) {
        this.activeTab = tab; // Update the active tab directly
        this.$router.push(tab); // Navigate to the new route
      }
    }
  },
};
</script>

<style scoped>
.theme--dark.v-tabs-items {
  background-color: transparent !important;
}

.market-containers {
  border-radius: 20px !important;
  background-color: #1e1e1e !important;
}
</style>

