<template>
    <v-tabs
        grow
        :show-arrows="false"
        color="secondary"
        background-color="#00000000"
    >
        <v-tab>Asset</v-tab>
        <v-tab-item>
            <main-earn-analysis-asset-balance-card :balance="12314" />
        </v-tab-item>
        <v-tab>Rewards</v-tab>
        <v-tab-item>
            <main-earn-analysis-reward-balance-card :balance="12314" />
        </v-tab-item>
    </v-tabs>
</template>
<script>
export default {
    data() {
        return {};
    },
    mounted() {
        // this.show7DayTrend(); // Display the 7-day trendLineChart chart initially
        // this.showPieChart();
    },
    methods: {},
};
</script>
<style>
.v-tab {
    min-width: 60px !important;
}
.theme--dark.v-tabs-items {
    background-color: transparent !important;
}
.market-container {
    width: 100vw !important;
    border-radius: 20px 20px 0 0 !important;
    background-color: #1e1e1e !important;
}
</style>
