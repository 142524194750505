<template>
  <v-tabs :show-arrows="false" background-color="#00000000">

    <v-tab>{{ $t('autoInvest.singleToken') }}</v-tab>
    <v-tab-item>
      <v-row>
        <v-col cols="12" xs="12" sm="6" md="4" lg="3" v-for="coin in filteredCoins">
          <main-earn-auto-invest-coin :key="coin.id" :name="coin.name" :image="coin.image" :apr="coin.apr"
            :earn="coin.earn" />
        </v-col>
      </v-row>
    </v-tab-item>

    <v-tab> {{ $t('autoInvest.portFolio') }}</v-tab>
    <v-tab-item>
      <v-row>
        <v-col cols="12" xs="12" sm="6" md="4" lg="3" v-for="coin in filteredCoins">
          <main-earn-auto-invest-coin :key="coin.id" :name="coin.name" :image="coin.image" :apr="coin.apr"
            :earn="coin.earn" />
        </v-col>
      </v-row>
    </v-tab-item>

    <v-tab> {{ $t('autoInvest.linked') }}</v-tab>
    <v-tab-item>
      <v-row>
        <v-col cols="12" xs="12" sm="6" md="4" lg="3" v-for="coin in filteredCoins">
          <main-earn-auto-invest-coin :key="coin.id" :name="coin.name" :image="coin.image" :apr="coin.apr"
            :earn="coin.earn" />
        </v-col>
      </v-row>
    </v-tab-item>
  </v-tabs>
</template>
<script>
export default {
  props: {
    search: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    coins: [],
  }),
  computed: {
    filteredCoins() {
      // Filter coins based on search value
      return this.coins.filter((coin) => {
        return coin.name.toLowerCase().includes(this.search.toLowerCase());
      });
    },
  },
  methods: {
    async fetchEarn() {
      this.loading = true;
      try {
        const response = await this.$axios.get(
          "https://api.uccoin.io/api/v1/earn/view"
        );
        this.loading = false;
        this.coins = response.data.earn;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
  },
  mounted() {
    this.fetchEarn();
  },
};
</script>
<style>
.v-tab {
  min-width: 60px !important;
}

.theme--dark.v-tabs-items {
  background-color: transparent !important;
}

.market-container {
  width: 100vw !important;
  border-radius: 20px 20px 0 0 !important;
  background-color: #1e1e1e !important;
}
</style>
