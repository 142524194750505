<template>
    <v-row>
        <v-col cols="12" xs="12" lg="4">
            <div>
                <v-slide-group mandatory class="mt-3" v-model="selectedOption" active-class="#5E6673">
                    <v-slide-item v-for="(option, index) in options" :key="index" v-slot="{ active, toggle }">
                        <v-btn x-small :color="active ? '#FAFAFA' : '#b7bdc6 '" text class="mx-1" :input-value="active"
                            @click="toggle">
                            {{ option }}
                        </v-btn>
                    </v-slide-item>
                </v-slide-group>

            </div>

            <div v-if="selectedOption === 0">
                <AllTab />
            </div>

            <div v-if="selectedOption === 1">
                <FlexibleTab />
            </div>

            <div v-if="selectedOption === 2">
                <LockedTab />
            </div>

        </v-col>
    </v-row>
</template>

<script>

import AllTab from './AllTab.vue';
import FlexibleTab from './FlexibleTab.vue';
import LockedTab from './LockedTab.vue';

export default {

    components: {
        AllTab,
        FlexibleTab,
        LockedTab,
    },

    data() {
        return {
            selectedOption: 0,
            options: ['All', 'Flexible', 'Locked'],
        }
    },
}
</script>

