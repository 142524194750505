<template>
  <div>
    <v-card v-if="!loading"  class="elevation-0" style="background-color:#00000000;border:solid 1px; border-color:#2b3139; border-radius:5px; margin-top:5px">
      <v-card-text>
        <v-row >
          <v-col cols="6">
            <span class="caption">Total Balance(USDT)</span>
            <h2 class="font-weight-bold mb-0">
              {{ balance | commaFormat }}
            </h2>
            <span class="sub-caption">={{ balance | commaFormat }} USDT</span>
          </v-col>
          <v-col cols="6" class="text-end">
            <v-btn to="/wallet/convert" style="margin-right:5px;color:black"  color="primary" 
                      >Convert
                    </v-btn>
                <v-btn
                      @click="transferClicked()"
                      >Transfer
                    </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-container v-else full-screen-container>
      <v-row justify="center">
        <v-col cols="12" class="text-center">
          <v-progress-circular
            indeterminate
            color="secondary"
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: true,
      stakes: [],
      balance: 0,
    };
  },
  mounted() {
    this.fetchFutureWallet();
  },
  methods: {
    fetchFutureWallet() {
      this.loading = true;
      this.balance = this.$store.getters.futureWallet.walletTotal;
      this.loading = false;
    },
    transferClicked() {
      this.$store.commit("setTransferType", "FUTURE");
      this.$router.push("/wallet/transfer");
    },
  },
};
</script>
<style>
.balance-card {
  margin-top: 20px !important;
  border-radius: 30px 30px 0 0 !important;
  width: 100%;
  margin: 0;
  padding: 0;
}

.sub-caption {
  font-size: 10px;
}

.full-screen-container {
  height: 90vh;
  width: 90vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
