<template>
    <v-row>
        <v-col cols="12">
            <!-- <SearchBarComponent /> -->

            <!-- Loading indicator -->
            <!-- <div v-if="isLoading" class="text-center">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </div> -->
            <div class="mt-5" v-if="!$vuetify.breakpoint.xs">
                <v-card class="pa-5" color="#00000000" style="border-radius: 5px; border: 1px solid #b7bdc6 ;">
                    <v-data-table :loading="isLoading" :headers="headers" :items="filteredData" :items-per-page="20"
                        @click:row="rowClicked($event)" class="transparent">

                        <template v-slot:item.name="{ item }">
                            <div class="py-3 d-flex align-center">
                                <div class="mr-3">
                                    <v-avatar size="30">
                                        <v-img :src="item.image"></v-img>
                                    </v-avatar>
                                </div>

                                <div class="mr-2 d-flex align-center">
                                    <!-- <span class="mr-2" style="font-size:16px;">{{ item.coin_pair_name }}</span><br /> -->
                                    <span class="">{{ (item.name) }}</span>
                                    <!-- <span style="font-size: 14px;"> {{ item.date }}</span><br /> -->
                                </div>
                            </div>
                        </template>

                        <template v-slot:item.balance="{ item }">
                            <!-- <span class="">${{ parseFloat(item.coin_value).toFixed(4) }}</span><br /> -->
                            <!-- <span style="font-size:10px;color:grey">${{ parseFloat(item.usdt_value).toFixed(2) }}</span> -->
                            <!-- <span style="font-size:10px;color:grey">{{ (item.coin_pair_name).replace('USDT', '')}}</span> -->
                            <!-- <span> {{ item.date }}</span><br/> -->
                        </template>

                        <!-- <template v-slot:item.actions="{ item }">
                        <v-btn text class="transparent mr-1">BUY <v-icon>mdi-chevron-right</v-icon></v-btn>
                        <v-btn text class="transparent mr-1">SELL <v-icon>mdi-chevron-right</v-icon></v-btn>
                        <v-btn text class="transparent mr-1">TRADE <v-icon>mdi-chevron-right</v-icon></v-btn>
                    </template> -->

                    </v-data-table>
                </v-card>
            </div>

            <div v-else>
                <div class="d-flex align-center justify-center mt-10" style="height: 100%">
                    <div class="d-flex flex-column align-center justify-center">
                        <v-icon x-large color="#b7bdc6">
                            mdi mdi-note-search-outline
                        </v-icon>
                        <span style="font-size: 12px; color: #b7bdc6">No Records Found</span>
                    </div>
                </div>
            </div>

        </v-col>
    </v-row>
</template>

<script>

import AllHistory from '../History/AllHistory'
import DepositHistory from '../History/DepositHistory.vue'
import WithdrawHistory from '../History/WithdrawHistory.vue'

import DepositWithdrawHistory from '../History/DepositWithdrawHistory.vue'
import BuySellHistory from '../History/BuySell.vue'
import EarnHistory from '../History/Earn.vue'

import { getWalletSingleCoin } from '@/services/CoinServices'
import numeral from 'numeral'

export default {

    components: {
        AllHistory,
        DepositHistory,
        WithdrawHistory,
        // DepositWithdrawHistory,
        BuySellHistory,
        EarnHistory,
    },

    data() {
        return {
            isLoading: false,
            selectedOption: 0,
            options: ['All', 'Deposit', 'Withdraw', 'Earn'],
            coinData: null,
            coinBalance: null,
            coinPrice: null,
            depositHistory: [],
            withdrawHistory: [],
        }
    },

    methods: {


        formattedPrice(price) {
            return numeral(price).format('$0,0.00'); // Format price as currency
        },

        formattedAmount(amount) {
            return numeral(amount).format('0,0.00'); // Format amount
        },

        onTradingClick(value) {
            const name = (value).replace('USDT', '')
            // console.log(name)
            this.$store.state.selectedCoin = name;
            this.$router.push(`/trades/trade-chart/${name}`);
        },

       
    },

    computed: {
        selectedCoin() {
            return this.$store.state.selectedCoin;
        },
    },

    mounted() {
        this.fetchCoinData()
    }
}
</script>