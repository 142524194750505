<template>
    <div>
        <v-card class="balance-card">
            <v-card-text>
                <div class="text-start">
                    <v-btn-toggle color="primary" mandatory group dense>
                        <v-btn class="mx-3" @click="show7DayTrend()">
                            7D
                        </v-btn>
                        <v-btn class="mx-3" @click="show1MonthTrend()">
                            30D
                        </v-btn>
                    </v-btn-toggle>
                </div>
                <v-row justify="space-between">
                    <v-col>
                        <span class="caption">Accumulative Rewards(BTC)</span>
                        <h2 class="font-weight-bold mb-0 white--text">
                            {{ balance | commaFormat }}
                        </h2>
                        <span class="sub-caption"
                            >={{ balance | commaFormat }} USDT</span
                        >
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" md="6">
                        <apexchart
                            ref="assetPieChart"
                            type="donut"
                            :options="pieChartOptions"
                            :series="pieSeries"
                        ></apexchart>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-card class="mt-3">
            <v-card-text>
                <v-row justify="center">
                    <v-col cols="12" md="6">
                        <apexchart
                            ref="columnChart"
                            type="bar"
                            :options="columnChartOptions"
                            :series="columnSeries"
                        ></apexchart>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
export default {
    props: {
        balance: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            lineChartOptions: {
                chart: {
                    type: "line",
                    animations: {
                        enabled: true,
                    },
                    toolbar: {
                        show: false,
                    },
                    background: "#1E1E1E",
                },
                colors: ["#fcd535"], // Yellow color
                theme: {
                    mode: "dark",
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    curve: "smooth", // Curvy lines
                    width: 2,
                },
                xaxis: {
                    type: "datetime",
                    labels: {
                        format: "MMM dd",
                        show: false, // Remove x-axis labels
                    },
                },
                yaxis: {
                    show: false, // Remove y-axis labels
                },
                grid: {
                    show: false, // Remove horizontal lines
                },
                //Tooltip font color black
            },
            series7Day: [
                {
                    name: "BTC 7-day",
                    data: [
                        [1647216000000, 38200],
                        [1647268800000, 39500],
                        [1647302400000, 38700],
                        [1647388800000, 40300],
                        [1647475200000, 39600],
                        [1647561600000, 41200],
                        [1647648000000, 40600],
                    ],
                },
            ],
            series1Month: [
                {
                    name: "BTC 1-month",
                    data: [
                        [1645036800000, 36000],
                        [1645123200000, 36500],
                        [1645209600000, 36200],
                        [1645296000000, 36800],
                        [1645382400000, 37200],
                        [1645468800000, 36500],
                        [1645555200000, 37000],
                        [1645641600000, 37400],
                        [1645728000000, 37900],
                        [1645814400000, 38400],
                        [1645900800000, 38100],
                        [1645987200000, 38900],
                        [1646073600000, 39300],
                        [1646160000000, 39700],
                        [1646246400000, 39100],
                        [1646332800000, 39700],
                        [1646419200000, 40100],
                        [1646505600000, 40800],
                        [1646592000000, 41200],
                        [1646678400000, 40700],
                        [1646764800000, 41500],
                        [1646851200000, 42000],
                        [1646937600000, 41600],
                        [1647024000000, 42300],
                        [1647110400000, 42800],
                        [1647196800000, 42600],
                        [1647283200000, 43100],
                        [1647369600000, 43600],
                        [1647456000000, 43800],
                    ],
                },
            ],
            columnChartOptions: {
                chart: {
                    type: "bar",
                    animations: {
                        enabled: true,
                    },
                    toolbar: {
                        show: false,
                    },
                    background: "#1E1E1E",
                },
                title: {
                    text: "Daily Rewards", // Add a title
                    align: "left",
                    style: {
                        fontSize: "16px",
                        fontWeight: "bold",
                        color: "#FFFFFF", // Yellow color
                    },
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: "55%",
                        endingShape: "rounded",
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ["transparent"],
                },
                xaxis: {
                    categories: [
                        "Mon",
                        "Tue",
                        "Wed",
                        "Thu",
                        "Fri",
                        "Sat",
                        "Sun",
                    ],
                },
                fill: {
                    opacity: 1,
                    colors: ["#fcd535"], // Yellow color
                },
                //Tooltip font color black
            },
            columnSeries: [
                {
                    data: [35, 41, 36, 26, 45, 48, 52],
                },
            ],
        };
    },
    mounted() {
        this.show7DayTrend(); // Display the 7-day trendLineChart chart initially
        // this.showPieChart();
    },
    methods: {
        show7DayTrend() {
            this.$refs.trendLineChart.updateSeries(
                JSON.parse(JSON.stringify(this.series7Day)),
                true
            );
        },

        show1MonthTrend() {
            this.$refs.trendLineChart.updateSeries(
                JSON.parse(JSON.stringify(this.series1Month)),
                true
            );
        },
    },
};
</script>
<style>
.balance-card {
    margin-top: 20px !important;
    border-radius: 30px 30px 0 0 !important;
    width: 100%;
    margin: 0;
    padding: 0;
}

.sub-caption {
    font-size: 10px;
}
</style>
