<template>
  <v-row>
    <v-col cols="6">
      <v-row>
        <v-col>
          <h1 class="font-weight-bold">Build your crypto portfolio</h1>
          <p class="grey--text darken-2">
            Start your first trade with these easy steps.
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" class="text-center">
          <icon
            icon="streamline:interface-id-thumb-mark-identification-password-touch-id-secure-fingerprint-finger-security"
            width="60"
            height="60"
          ></icon>
        </v-col>
        <v-col cols="9">
          <h4>Verify your identity</h4>
          <p class="grey--text darken-2">
            Complete your profile and verify your identity to start trading.
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" class="text-center">
          <icon
            icon="fluent:money-hand-20-regular"
            width="60"
            height="60"
          ></icon>
        </v-col>
        <v-col cols="9">
          <h4>Fund your account</h4>
          <p class="grey--text darken-2">
            Add funds to your crypto account to start trading crypto. You can
            add funds with a variety of payment methods.
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" class="text-center">
          <icon icon="ic:round-candlestick-chart" width="60" height="60"></icon>
        </v-col>
        <v-col cols="9">
          <h4>Start trading</h4>
          <p class="grey--text darken-2">
            You're good to go! Buy/sell crypto, set up recurring buys for your
            investments, and discover what UCCoin has to offer.
          </p>
        </v-col>
      </v-row>
      <v-btn
        class="ml-6 mt-6 black--text"
        color="secondary"
        x-large
        to="/signin"
        >Get Started</v-btn
      >
    </v-col>
    <v-col cols="6" class="text-center">
      <img id="phones" src="@/assets/images/trial-3.png" />
    </v-col>
  </v-row>
</template>
<script>
export default {
  data: () => ({
    loading: true,
  }),
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
  computed: {
    marketCoins() {
      return this.$store.getters.marketCoins;
    },
    filteredCoins() {
      return this.marketCoins.filter((coin) => coin.type === "MAIN");
    },
  },
};
</script>
<style>
#phones {
  max-height: 600px; /* 50% of 280px */
  max-width: 600px; /* 50% of 280px */
  object-fit: contain;
}
.theme--dark.v-tabs-items {
  background-color: transparent !important;
}
</style>
