<template>
    <v-row align="center">
        <v-col cols="10">
            <div class="d-flex align-items-center">
                <v-avatar class="mr-1 pr-1">
                    <img
                        src="https://cdn.vuetifyjs.com/images/john.jpg"
                        alt="John"
                    />
                </v-avatar>
                <div>
                    <div class="ml-2 font-weight-bold">{{ user.email }}</div>
                    <div class="text-left">
                        
                        <v-chip v-if="user.account_status == 'Unverified'" class="ma-2">{{ user.account_status }}</v-chip>
                        <v-chip v-else-if="user.account_status == 'Verified'" class="ma-2"> <v-icon color="success" size="15">mdi-check-circle-outline</v-icon>{{ user.account_status }}</v-chip>
                        <v-chip
                            v-if="client.membership_level != 'NO_BADGE'"
                            class="ma-2"
                            color="greyPrimary"
                            small
                            label
                        >
                            {{ client.membership_level }}
                        </v-chip>
                    </div>
                </div>
                <v-divider vertical style="margin-left:20px"></v-divider>
                    <v-row style="margin-left:20px">
                        <v-col cols="3">
                            <span style="font-size:11px;color:grey">User ID</span><br/>
                            <span>{{user.user_unique_id}}</span>
                        </v-col>
                        <v-col cols="3">
                            <span style="font-size:11px;color:grey">VIP Level</span><br/>
                            <span v-if="client.membership_level">Regular User</span>
                            <span v-else>{{ client.membership_level }}</span>
                        </v-col>
                        <v-col cols="3">
                            <span style="font-size:11px;color:grey">User Type</span><br/>
                            <span v-if="client.membership_level">Personal</span>
                        </v-col>
                        <v-col cols="3">
                            <span style="font-size:11px;color:grey">Verification</span><br/>
                            <v-btn to="/account/verify" small rounded color="primary" style="color:black">Verify Now</v-btn>
                            
                        </v-col>
                    </v-row>
            </div>
        </v-col>
    </v-row>
</template>
<script>
export default {
    computed: {
        client() {
            return this.$store.getters.client;
        },
        user() {
            return this.$store.getters.user;
        },
    },
};
</script>
