<template>
  <v-row justify="center">
    <v-col cols="5">
      <!-- <v-toolbar>
            <v-btn icon color="secondary" to="account">
                <icon
                    icon="material-symbols:arrow-back"
                    width="24"
                    height="24"
                ></icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-toolbar-title class="">Payment</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn to="settings" icon>
                <icon icon="heroicons:cog-solid" width="24" height="24" />
            </v-btn>
        </v-toolbar> -->
      <v-container>
        <div v-if="items.length > 0">
          <v-row class="transparent">
            <v-list class="transparent">
              <v-list-item
                class="transparent my-2"
                v-for="(item, index) in items"
                :key="index"
                :to="item.to"
              >
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    <v-icon left> {{ item.icon }} </v-icon>
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon>
                    <icon icon="fa:chevron-right" width="16" height="16" />
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-row>
        </div>
        <div v-else>
          <v-card class="mt-4 transparent elevation-0">
            <v-card-text class="text-center">
              <v-row align="start" class="ma-0">
                <h6 class="font-weight-bold">No payment method yet</h6>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-container>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data: () => ({
    items: [],
  }),
};
</script>
