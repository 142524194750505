<template>
    <div>
        <apexchart type="donut" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
export default {
    props: {
        chartData: {
            type: Array,
            required: true
        },

        series: {
            type: Array,
            required: true,
        },

        labels: {
            type: Array,
            required: true,
        }
    },
    data() {
        return {
            chartOptions: {

                chart: {
                    width: 480,
                    type: 'pie',
                },

                labels: this.labels,

                legend: {
                    show: true,
                    fontSize: '14px',
                    fontWeight: 400,
                    labels: {
                        colors: '#b7bdc6 ',
                    },
                },

                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 350
                        },
                        legend: {
                            position: 'right',
                            labels: {
                                colors: '#b7bdc6 ',
                            }
                        }
                    }
                }],
                colors: ['#F6465D', '#FF707E', '#FF99A0', '#0ECB81', '#32D993', '#5AE6A7'],
            },

        };
    },
}
</script>