<template>
  <v-row align="center" style="margin-top:-80px" @click="coinClicked()">
    <v-col cols="2">
      <div class="d-flex justify-content-start align-items-center">
        <v-avatar size="25">
          <img :src="image" :alt="name" />
        </v-avatar>
        <strong class="ml-3 mr-2">{{ name }}</strong>
        <span class="caption grey--text darken-2">USDT</span>
      </div>
    </v-col>
    <v-col cols="3" class="text-center">
      <p class="font-weight-bold white--text text--lighten-1 mb-0">
        {{ amount }}
      </p>
    </v-col>
    <v-col cols="3" class="text-center">
      <p class="font-weight-bold greenPrimary--text text--lighten-1 mb-0">
        {{ percentWithSign }}%
      </p>
    </v-col>
    <v-col cols="2" class="text-center">
      <apexchart
        ref="chart"
        type="line"
        :options="chartOptions"
        :series="chartSeries"
        class="small-chart"
      />
    </v-col>
    <v-col cols="2" class="text-right">
      <p class="font-weight-bold white--text text--lighten-1 mb-0">
        {{ amount }}
      </p>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data: () => ({
    chartOptions: {
      chart: {
        animations: {
          enabled: true, // Enable animations for smooth curves
        },
        toolbar: {
          show: false, // Hide the toolbar
        },
        zoom: {
          enabled: false, // Disable zooming
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        enabled: false, // Use shared tooltip
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      xaxis: {
        labels: {
          show: false, // Hide x-axis labels
        },
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: false, // Hide y-axis labels
        },
      },
      grid: {
        show: false, // Hide grid lines
      },
    },
    chartSeries: [
      {
        data: [],
      },
    ],
  }),
  props: {
    name: String,
    image: String,
    percent: String,
    amount: String,
  },
  computed: {
    percentColor() {
      //Return the color based on the percent value if positive or negative
      return this.percent >= 0 ? "greenPrimary" : "redPrimary";
    },
    percentWithSign() {
      //Return the percent value with a sign
      return this.percent >= 0 ? "+" + this.percent : this.percent;
    },
  },
  methods: {
    coinClicked() {
      this.$store.state.selectedCoin = this.name;
      this.$router.push(`/trades/spot/${this.name}`);
    },
  },
  mounted() {
    //Insert 9 random values into the chartSeries array then add latest price at the end
    for (let i = 0; i < 9; i++) {
      this.chartSeries[0].data.push(Math.floor(Math.random() * 100));
    }
    this.chartSeries[0].data.push(this.amount);
  },
};
</script>
<style>
.percent-card {
  font-weight: 550;
}
.small-chart {
  width: 150px;
}
</style>
