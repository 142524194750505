<template>
    <v-row class="">

        <!-- <v-col cols="12">
            <span>Market Trades</span>
        </v-col> -->

        <v-col cols="12">
            <v-row>
                <v-col cols="4">
                    <div align="start" style=" font-size: 12px; color: #848e9c;">{{ $t('trade.price') }} (USDT)</div>
                </v-col>
                <v-col align=" end" cols=" 4">
                    <div class="item"> <span style="font-size: 12px; color: #848e9c;">{{ $t('trade.amount') }} ({{ price
                    }})</span></div>
                </v-col>
                <v-col align="end" style=" font-size: 12px; color: #848e9c;" cols=" 4">
                    <div class="item"><span style="font-size: 12px; color: #848e9c;">{{ $t('trade.time') }}</span></div>
                </v-col>
            </v-row>
        </v-col>

        <v-col cols="12">

            <v-row style="line-height: 1px;" v-for="(trade, index) in TradesData.slice(0, 50)" :key="index">
                <v-col cols="4">
                    <div class="d-flex flex-column">
                        <div style="font-size: 14px" class="">
                            <span :style="{ textAlign: 'center', color: trade.side === 'buy' ? '#0ECB81' : '#F6465D' }">
                                {{ price }}</span>
                        </div>
                    </div>
                </v-col>
                <v-col align="end" cols="4">
                    <div class="d-flex flex-column">
                        <span style="font-size: 14px" class="" :key="index">
                            {{ formattedAmount(trade.sz) }}
                        </span>
                    </div>

                </v-col>
                <v-col align="end" cols="4">
                    <div class="d-flex flex-column">
                        <span style="font-size: 14px" class="" :key="index">
                            {{ formatTime(trade.ts) }}
                        </span>
                    </div>
                </v-col>
            </v-row>
        </v-col>

    </v-row>
</template>

<script>
import numeral from 'numeral';

export default {
    name: "trades",

    props: {
        selectedCoin: {
            type: String,
            required: true,
        },

        TradesData: {
            type: Array,
            default: () => [],
        },

        price: {
            type: [String, Number],
            required: true,
        }
    },

    data: () => ({
        historicalData: [],
    }),

    computed: {
        formattedAmount() {
            return (amount) => numeral(amount).format('0.00000');
        },
    },

    methods: {
        wow() {
            console.log('ok')
        },
        // getHistoricalData() {
        //    
        // },
        formatTime(timestamp) {
            if (!isNaN(timestamp)) {
                const date = new Date(parseInt(timestamp)); // Ensure timestamp is parsed as integer
                const hours = date.getHours();
                const minutes = date.getMinutes();
                const seconds = date.getSeconds();
                // Pad single-digit hours, minutes, and seconds with leading zeros
                const formattedTime = [
                    String(hours).padStart(2, '0'),
                    String(minutes).padStart(2, '0'),
                    String(seconds).padStart(2, '0')
                ].join(':');
                return formattedTime;
            } else {
                return ''; // Return empty string if timestamp is not valid
            }
        }
    },

}
</script>


<style>
.item {
    font-size: 12px;
    color: #848e9c;
}

.list-item {
    color: #b7bdc6;
    /* cursor: pointer; */
    font-size: 12px;
    font-weight: 400;
    text-align: right;
}
</style>