<template>
    <v-row align="center">
        <v-col cols="10">
            <div class="d-flex align-items-center">
                <v-avatar class="mr-1 pr-1">
                    <img
                        src="https://cdn.vuetifyjs.com/images/john.jpg"
                        alt="John"
                    />
                </v-avatar>
                <div>
                    <div class="ml-2 font-weight-bold">{{ user.email }}</div>
                    <div class="text-left">
                       
                        <v-chip v-if="client.user.account_status == 'Unverified'" class="ma-2">{{ client.user.account_status }}</v-chip>
                        <v-chip v-else-if="client.user.account_status == 'Verified'" class="ma-2"> <v-icon color="success" size="15">mdi-check-circle-outline</v-icon>{{ client.user.account_status }}</v-chip>
                        <v-chip
                            v-if="client.membership_level != 'NO_BADGE'"
                            class="ma-2"
                            color="greyPrimary"
                            small
                            label
                        >
                            {{ client.membership_level }}
                        </v-chip>
                    </div>
                </div>
            </div>
        </v-col>
        <v-col cols="2" class="text-right">
            <v-btn icon>
                <icon icon="fa:chevron-right" width="16" height="16"></icon>
            </v-btn>
        </v-col>
    </v-row>
</template>
<script>
export default {
    computed: {
        client() {
            return this.$store.getters.client;
        },
        user() {
            return this.$store.getters.user;
        },
    },
};
</script>
