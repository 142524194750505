<template>
  <v-row justify="center">
    <v-col cols="12" xs="12" lg="10">

      <v-toolbar class="transparent elevation-0">
        <v-btn icon @click="goBack">
          <v-icon>mdi mdi-chevron-left</v-icon>
        </v-btn>
        <v-toolbar-title> <span style="font-size: 1rem; font-weight: 500;">Earn</span> </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon> </v-btn>
      </v-toolbar>


      <v-col cols="12" xs="12" lg="4">
        <SearchBarComponent placeholder="Search Coin" :search.sync="search" />
      </v-col>

      <v-tabs v-model="tab" background-color="#00000000" bg-color="transparent" class="tabs-right">

        <v-tab value="one">Low Risk</v-tab>
        <v-tab-item class="pa-1">
          <LowRiskTab />
        </v-tab-item>

        <v-tab value="two">High Yield</v-tab>
        <v-tab-item class="pa-1">
          <HighYieldTab />
        </v-tab-item>

        <v-tab :to="{ path: '/wallet/auto_invest' }" value="three">Auto-Invest</v-tab>

      </v-tabs>

      <!-- <v-row justify="space-between" class="mt-3">
        <v-col class="text-center px-2">
          <v-btn to="/wallet/simple-earn" elevation="0" fab mix-width="60" min-heigth="60">
            <icon icon="ph:coins-duotone" color="#fcd535" width="35" height="35" />
          </v-btn>
          <p id="btn-caption">Simple Earn</p>
        </v-col>
        <v-col class="text-center px-2">
          <v-btn to="/wallet/auto-invest" elevation="0" fab mix-width="60" min-heigth="60" id="custom-btn">
            <icon icon="ph:arrows-counter-clockwise-duotone" color="#fcd535" width="35" height="35" />
          </v-btn>
          <p id="btn-caption">Auto Invest</p>
        </v-col>
        <v-col class="text-center px-2">
          <v-btn @click="walletSelected()" elevation="0" fab mix-width="60" min-heigth="60" id="custom-btn">
            <icon icon="ph:wallet-duotone" color="#fcd535" width="35" height="35" />
          </v-btn>
          <p id="btn-caption">Earn Wallet</p>
        </v-col>
      </v-row> -->

      <!-- <main-earn-coinlist :search="search" /> -->
    </v-col>
  </v-row>
</template>
<script>

import store from "@/store";
import LowRiskTab from "./Wallet/Tabs/LowRisk/LowRiskTab.vue";
import HighYieldTab from "./Wallet/Tabs/HighYieldTab.vue";
import SearchBarComponent from "@/components/ui/SearchBarComponent.vue";

export default {
  name: "earn",
  data: () => ({
    search: "",
  }),

  components: {
    LowRiskTab,
    HighYieldTab,
    SearchBarComponent,
  },

  methods: {
    goBack() {
      this.$router.go(-1);
    },
    walletSelected() {
      this.$store.commit("setWalletView", "tab-4");
      this.$router.push("/wallet");
    },
  },
  mounted() { },
  beforeRouteEnter(to, from, next) {
    const isAuthenticated = store.getters.isAuthenticated;
    if (isAuthenticated) {
      next();
    } else {
      next("/signin");
    }
  },
};
</script>
