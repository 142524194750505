<template>
  <div class="my-10">

    <div class="d-flex flex-column align-center text-center">
      <!-- <h4>Verify Your Identity </h4> -->
      <span style="font-size: 14px; color: #9493ac; font-weight: 300;">
        As a part of the KYC (Know your customer) process, we request all our customers to verify their identity.
      </span>
      <!-- <v-avatar class="mt-5" size="200">
        <img src="../../../assets/images/user.jpg" />
      </v-avatar> -->
    </div>

    <div v-if="!loading">
      <v-card class="mt-4 transparent elevation-0" v-if="status == 'PENDING'">

        <v-alert title="Alert title" border="left" type="warning" outlined>
          Pending KYC
        </v-alert>

        <!-- <v-card-text class="text-center">
                <h3 class="text-center secondary--text"></h3>
              </v-card-text> -->
      </v-card>

      <v-stepper v-else-if="status == 'DISSAPROVED' || status == 'NOEXISTINGKYC'" v-model="step"
        class="transparent elevation-0">
        <v-stepper-header class="elevation-0">
          <v-stepper-step :complete="step > 1" step="1" />

          <v-divider></v-divider>

          <v-stepper-step :complete="step > 2" step="2" />

          <v-divider></v-divider>

          <v-stepper-step :complete="step > 3" step="3" />

          <v-divider></v-divider>

          <v-stepper-step step="4" />
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-form ref="form" lazy-loading>
              <div>
                <label class="mb-2">ID Type</label>

                <SelectComponent :bindData.sync="idType" :dense="true" :items="idTypes" :rules="rules.required" />
                <!-- {{ idType }} -->
              </div>

              <div>
                <label class="mb-2">ID Number</label>
                <v-text-field outlined v-model="id_number" :dense="true" placeholder="Enter ID number"
                  :rules="rules.required" />
              </div>
            </v-form>

            <div class="text-end">
              <v-btn block rounded x-large color="primary black--text" :loading="btnLoading" @click="checkForm()"> Next
              </v-btn>
            </div>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-form ref="form2" lazy-loading>
              <label>{{ status == "DISSAPROVED" ? "Re " : "" }}Upload Selfie
                Photo</label>
              <v-file-input prepend-icon="" prepend-inner-icon="mdi-paperclip" class="mt-3" dense outlined
                v-model="image_name" accept="image/*" placeholder="Selfie Photo" :rules="rules.required"
                :error-message="errorMessage.photo" @change="convertImage" />
            </v-form>

            <div class="text-end">

              <v-btn block rounded x-large color="primary black--text" :loading="btnLoading" @click="checkForm()"> Next
              </v-btn>

              <!-- <v-btn color="primary" :loading="btnLoading" @click="checkForm()"> Continue </v-btn> -->
            </div>
          </v-stepper-content>

          <v-stepper-content step="3">
            <v-form ref="form3" lazy-loading>
              <label>{{ status == "DISSAPROVED" ? "Re " : "" }}Upload ID Front
                Photo</label>

              <v-file-input prepend-icon="" prepend-inner-icon="mdi-paperclip" class="mt-3" dense outlined
                v-model="image_name" accept="image/*" placeholder="Front ID Photo" :rules="rules.required"
                :error-message="errorMessage.photo" @change="convertImage" />

            </v-form>

            <div class="text-end">
              <v-btn block rounded x-large color="primary black--text" :loading="btnLoading" @click="checkForm()"> Next
              </v-btn>

              <!-- <v-btn color="primary" :loading="btnLoading" @click="checkForm()"> Continue </v-btn> -->
            </div>
          </v-stepper-content>

          <v-stepper-content step="4">
            <v-form ref="form4" lazy-loading>
              <label>{{ status == "DISSAPROVED" ? "Re " : "" }}Upload ID Back
                Photo</label>

              <v-file-input prepend-icon="" prepend-inner-icon="mdi-paperclip" class="mt-3" dense outlined
                v-model="image_name" accept="image/*" placeholder="Back ID Photo" :rules="rules.required"
                :error-message="errorMessage.photo" @change="convertImage" />

            </v-form>

            <div class="text-end">
              <v-btn block rounded x-large color="primary black--text" :loading="btnLoading" @click="checkForm()"> Next
              </v-btn>
              <!-- <v-btn color="primary" :loading="btnLoading" @click="checkForm()"> Continue </v-btn> -->
            </div>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>

      <v-card class="transparent elevation-0" v-else-if="status == 'APPROVED'">

        <v-alert title="Alert title" border="left" type="success" outlined>
          Your account is verified
        </v-alert>

      </v-card>

    </div>

    <v-container v-else fluid class="text-center">
      <v-progress-circular indeterminate color="primary" class="mb-0"></v-progress-circular>
    </v-container>
  </div>
</template>
<script>

import TextfieldComponent from '@/components/ui/TextfieldComponent.vue';
import SelectComponent from '@/components/ui/SelectComponent.vue';

export default {

  components: {
    TextfieldComponent,
    SelectComponent,
  },

  data: () => ({
    loading: false,
    btnLoading: false,
    step: 1,
    idType: "Driver's License",
    idTypes: ["Driver's License", "Government ID", "Passport, Others"],
    idNumber: "",
    id_number: "",
    image: null,
    image_name: null,
    status: null,
    statuses: [],
    errorMessage: {
      photo: "Photo is required",
    },
    rules: {
      required: [(v) => !!v || "Field is required"],
    },
  }),
  methods: {
    async checkForm() {
      if (this.step == 1) {
        if (this.$refs.form.validate()) {
          this.step = 2;
        }
      } else if (this.step == 2) {
        if (this.$refs.form2.validate()) {
          this.sendImage("SELFIE");
        }
      } else if (this.step == 3) {
        if (this.$refs.form3.validate()) {
          this.sendImage("FRONTID");
        }
      } else {
        if (this.$refs.form4.validate()) {
          this.sendImage("BACKID");
        }
      }
    },
    convertImage() {
      try {
        let reader = new FileReader();
        reader.onload = () => {
          this.image = reader.result;
        };
        reader.readAsDataURL(this.image_name);
      } catch (error) {
        this.image_name = null;
        this.image = null;
      }
    },
    sendImage(imageType) {
      this.btnLoading = true
      this.$axios
        .post("https://api.uccoin.io/api/v2/upload/kyc", {
          id_type: this.idType,
          // id_number: this.id_number,
          img_type: imageType,
          image: this.image,
        })
        .then((response) => {
          if (response.data.message == "success") {
            this.btnLoading = false
            this.image = null;
            this.image_name = null;
            if (this.step == 2) {
              if (
                this.statuses[this.step] == "DISSAPROVED" ||
                this.statuses[this.step] == "NULL"
              ) {
                this.step = 3;
              } else if (
                this.statuses[this.step + 1] == "DISSAPROVED" ||
                this.statuses[this.step + 1] == "NULL"
              ) {
                this.step = 4;
              } else {
                this.$swal({
                  title: "Success",
                  text: "Your KYC has been submitted",
                  icon: "success",
                  timer: 3000,
                  showConfirmButton: false,
                });
              }
            } else if (this.step == 3) {
              if (
                this.statuses[this.step] == "DISSAPROVED" ||
                this.statuses[this.step] == "NULL"
              ) {
                this.step = 4;
              } else {
                this.$swal({
                  title: "Success",
                  text: "Your KYC has been submitted",
                  icon: "success",
                  timer: 3000,
                  showConfirmButton: false,
                });

              }
            } else if (this.step == 4) {
              this.checkKYC()
              this.$swal({
                title: "Success",
                text: "Your KYC has been submitted",
                icon: "success",
                timer: 3000,
                showConfirmButton: false,
              });

            } else {

              this.$swal({
                title: "Success",
                text: "Your KYC has been submitted",
                icon: "success",
                timer: 3000,
                showConfirmButton: false,
              });

            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    checkKYC() {
      this.loading = true;
      this.$axios
        .get("https://api.uccoin.io/api/v2/upload/check-kyc")
        .then((response) => {
          if (response.data.message === "no_existing_kyc") {
            this.statuses[1] = "NULL";
            this.statuses[2] = "NULL";
            this.statuses[3] = "NULL";
            this.status = "NOEXISTINGKYC";
          } else {
            this.statuses[1] = response.data.selfie
              ? response.data.selfie.status
              : "NULL";
            this.statuses[2] = response.data.front
              ? response.data.front.status
              : "NULL";
            this.statuses[3] = response.data.back
              ? response.data.back.status
              : "NULL";
            let idNumber = response.data.id_number;
            let idType = response.data.id_type;
            switch (true) {
              case this.statuses.includes("NULL"):
                this.step = this.statuses.indexOf("NULL") + 1;
                // console.log(this.step);
                this.status = "NOEXISTINGKYC";
                break;
              case this.statuses.includes("PENDING"):
                this.status = "PENDING";
                break;
              case this.statuses.includes("DISSAPROVED"):
                this.status = "DISSAPROVED";
                this.errorMessage.photo =
                  "Your photo is not clear. Please try again.";
                this.step = this.statuses.indexOf("DISSAPROVED") + 1;
                break;
              default:
                this.status = "APPROVED";
            }

            if (idNumber) {
              this.idNumber = idNumber;
            }
            if (idType) {
              this.idType = idType;
            }
          }
          // console.log(this.statuses);
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.checkKYC();
  },
};
</script>
<style>
/* Position the logout button at the bottom right corner */
.v-btn--absolute {
  position: fixed;
  bottom: 16px;
  right: 16px;
}

/* Button Spinner */
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
  