<template>
    <v-row>

        <v-col class="mt-2" v-if="$vuetify.breakpoint.xs" align="start" cols="12" xs="12" md="8" lg="6">
            <SearchBarComponent :search.sync="search" placeholder="Search Coin" />
        </v-col>

        <v-col v-if="!$vuetify.breakpoint.xs" cols="12" class="mt-3">
            <!-- <v-card class="pa-5" color="#272727" style="border-radius: 10px;"> -->
            <v-data-table :loading="isLoading" :headers="headers" :items="filteredCoins" :items-per-page="20"
                @click:row="rowClicked($event)" class="transparent">

                <template v-slot:top>
                    <v-toolbar flat color="transparent">
                        <v-row>
                            <v-col cols="12" xs="12" md="6" lg="3">
                                <SearchBarComponent :search.sync="search" placeholder="Search Coin" />
                            </v-col>
                        </v-row>
                    </v-toolbar>
                </template>

                <template v-slot:item.name="{ item }">
                    <div class="py-3 d-flex align-center">
                        <div class="mr-3">
                            <v-avatar size="30">
                                <v-img :src="item.image"></v-img>
                            </v-avatar>
                        </div>

                        <div class="mr-2 d-flex align-center">
                            <!-- <span class="mr-2" style="font-size:16px;">{{ item.coin_pair_name }}</span><br /> -->
                            <span class="">{{ (item.name) }}</span>
                            <!-- <span style="font-size: 14px;"> {{ item.date }}</span><br /> -->
                        </div>
                    </div>
                </template>

                <template v-slot:item.apr="{ item }">
                    <span>{{ item.apr }}%</span>
                </template>

            </v-data-table>
            <!-- </v-card> -->
        </v-col>


        <!-- mobile view -->
        <v-col v-else cols="12">
            <!-- check if loading -->
            <div v-if="isLoading" class="text-center">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </div>

            <div v-else>
                <!-- check if empty -->
                <div v-if="!filteredCoins.length <= 0">
                    <v-row align="center" justify="center" class="py-3" :key="id"
                        v-for="{ name, id, image, apr } in filteredCoins">
                        <v-col cols="12">
                            <div class="d-flex align-center justify-space-between">
                                <div>
                                    <v-avatar size="30" class="mr-2">
                                        <v-img :src="image"></v-img>
                                    </v-avatar>
                                    <span style="font-size: 14px; font-weight: 500;"> {{ name }}</span>
                                </div>
                                <span style="font-size: 14px; font-weight: 500;">{{ apr }}% APR</span>
                            </div>
                        </v-col>
                    </v-row>
                </div>

                <div v-else class="d-flex align-center justify-center mt-10" style="height: 100%">
                    <div class="d-flex flex-column align-center justify-center">
                        <v-icon x-large color="#b7bdc6">
                            mdi mdi-note-search-outline
                        </v-icon>
                        <span style="font-size: 12px; color: #b7bdc6">No Records Found</span>

                        <div class="mt-3">
                            <ButtonComponent :onClick="handleSubscribeClick" style="color: #1C1D28 ;" color="primary"
                                label="Subscribe Now" />
                        </div>

                    </div>
                </div>

            </div>
        </v-col>
    </v-row>
</template>

<script>

import ButtonComponent from '@/components/ui/ButtonComponent.vue';
import SearchBarComponent from '@/components/ui/SearchBarComponent.vue';
import axios from "axios";

export default {

    components: {
        ButtonComponent,
        SearchBarComponent,
    },

    data: () => ({
        isLoading: false,
        coins: [],
        search: '',
        headers: [
            {
                value: "name",
                text: "Token",
                align: "start",
                sortable: true,
            },

            {
                value: "apr",
                text: "Appraisal",
                sortable: true,
                align: "start",
            },

        ]
    }),

    computed: {
        filteredCoins() {
            return this.coins.filter((coin) => {
                return coin.apr !== "Not available" &&
                    coin.name.toLowerCase().includes(this.search.toLowerCase());
            });
        },
    },

    methods: {

        async fetchEarn() {

            try {
                this.isLoading = true;
                const response = await axios.get(
                    "https://api.uccoin.io/api/v1/earn/view"
                );
                this.coins = response.data.earn;
            } catch (error) {
                console.log(error);
            } finally {
                this.isLoading = false
            }
        },

        handleSubscribeClick() {
            this.$router.push(`wallet/simple_earn`)
        }
    },

    mounted() {
        this.fetchEarn()
    },
}
</script>