import axios from "axios";

const apiUrl = 'https://api.uccoin.io/api'

export const fetchProfile = async () => {
    try {
        const response = await axios.get(`${apiUrl}/v1/profile`);
        return response.data.profile;

    } catch (error) {
        console.error('Error fetching Profile:', error);
        throw error;
    }

};

