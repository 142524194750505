<template>
  <v-row>
    <v-col cols="12" class="mb-0 pb-1">
      <v-btn-toggle
        v-model="transactionType"
        color="transparent"
        mandatory
        class="full-width-toggle"
        @change="onToggleButtonChange"
      >
        <v-btn
          v-for="(type, index) in transactionTypes"
          :key="index"
          :value="type"
          small
          :class="transactionType === type ? 'white--text' : 'grey--text'"
          :color="transactionType === type ? type.color : 'greyPrimary'"
        >
          {{ type.label }}
        </v-btn>
      </v-btn-toggle>
    </v-col>
    <v-col cols="12" class="mt-0 py-1">
      <v-select
        :items="['MARKET', 'LIMIT']"
        v-model="transactionPlatform"
        solo
        class="centered-input"
        height="28px"
      ></v-select>
    </v-col>
    <v-col cols="12" class="mt-0 py-1">
      <v-text-field
        disabled
        label="Market Price"
        solo
        height="28px"
      ></v-text-field>
    </v-col>
    <v-col cols="12" class="mb-0 pb-1">
      <v-btn-toggle
        v-model="priceType"
        color="transparent"
        mandatory
        class="full-width-toggle"
        @change="onToggleButtonChange"
      >
        <v-btn
          v-for="(type, index) in priceTypes"
          :key="index"
          :value="type"
          small
          :class="priceType === type ? 'white--text' : 'grey--text'"
          color="greyPrimary"
        >
          {{ type }}
        </v-btn>
      </v-btn-toggle>
    </v-col>
    <v-col cols="12" class="mt-0 py-1">
      <v-text-field
        prepend-inner-icon="mdi-minus"
        append-icon="mdi-plus"
        label="(BTC)"
        solo
        height="28px"
      ></v-text-field>
    </v-col>
    <v-col cols="12" class="mt-0 py-1">
      <v-btn
        block
        medium
        :color="transactionType === 'buy' ? 'greenPrimary' : 'redPrimary'"
      >
        <span class="text-capitalize"
          >{{ transactionType === "buy" ? "Buy" : "Sell" }} BTC</span
        >
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data: () => ({
    marketType: 2,
    transactionTypes: [
      { label: "Buy", value: "buy", color: "greenPrimary" },
      { label: "Sell", value: "sell", color: "redPrimary" },
    ],
    transactionType: "buy",
    transactionPlatform: "MARKET",
    priceTypes: ["Amount", "Total"],
    priceType: "Amount",
  }),
  computed: {
    buttonTypeColor() {
      return this.marketType == 1 ? "greenPrimary" : "redPrimary";
    },
  },
  methods: {
    onToggleButtonChange(value) {
      console.log("Selected Button:", value);
    },
  },
  // beforeRouteEnter(to, from, next) {
  //     if (!localStorage.getItem("userRole")) {
  //         return next("/signin");
  //     }
  //     next();
  // },
  mounted() {},
};
</script>
<style>
.toolbar-buttons {
  background-color: transparent !important;
}
.trade-container {
  width: 100vw !important;
  border-radius: 20px !important;
  background-color: #1e1e1e !important;
}
.full-width-toggle {
  display: flex;
  width: 100%;
}

.full-width-toggle .v-btn {
  flex-grow: 1;
  flex-basis: 0;
}
.v-text-field.v-text-field--solo .v-input__control {
  height: 28px !important;
}
.v-text-field.v-text-field--solo .v-input__control .v-input__slot {
  height: 28px !important;
}
</style>
