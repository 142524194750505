<template>
    <v-row justify="space-between" align="center">
      <v-col>
        <h4 >Your Estimated Balance</h4>
        <h1 style="color:white" v-if="!loading" class="font-weight-bold">
          {{ balance | commaFormat }} 
          <span style="font-size:15px">USDT = ${{ balance | commaFormat }}</span>
          
        </h1>
            <v-btn to="/wallet/deposit" color="primary" large class="white--text" style="margin:5px">Deposit</v-btn
            >
            <v-btn to="/trades/spot/BTC" color="primary" large class="white--text" style="margin:5px"
            >Trade</v-btn
            >
            <v-btn to="/wallet/convert" color="primary" large class="white--text" style="margin:5px"
            >Convert</v-btn
        >
      </v-col>
      <!-- <v-col class="text-right">
        <v-btn to="deposit" color="secondary" class="white--text" rounded
          >Deposit</v-btn
        >
      </v-col> -->
    </v-row>
  </template>
  
  <script>
  export default {
    data: () => ({
      loading: true,
    }),
    computed: {
      balance() {
        return this.$store.state.wallet.usdtWallet[0].usdtWallet;
      },
    },
    methods: {
      async fetchWallet() {
        await this.$store.dispatch("setWallet");
        this.loading = false;
      },
    },
    mounted() {
      this.fetchWallet();
    },
  };
  </script>
  