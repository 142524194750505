<template>
    <v-card color="#00000000">
      <v-card-text>
        <v-row>
          <v-list  style="background-color:#00000000" v-if="!loading">
            <v-list-item v-for="(coin, index) in coins" :key="index">
              <v-list-item-avatar size="20">
                <v-img :src="coin.image"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title style="font-weight:300">{{
                  coin.name
                }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content class="text-right">
                <v-list-item-title class="subtitle-2">{{
                  coin.coin_value | commaFormat
                }}</v-list-item-title>
                <span class="sub-caption"
                  >={{ coin.value | commaFormat }} USDT</span
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-skeleton-loader
            v-else
            type="list-item-three-line"
            class="mx-auto"
            :loading="loading"
          ></v-skeleton-loader>
        </v-row>
      </v-card-text>
    </v-card>
  </template>
  <script>
  export default {
    props: {
      coins: {
        type: Array,
        required: true,
      },
      loading: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {};
    },
    mounted() {},
    methods: {},
  };
  </script>
  <style>
  .portfolio-card {
    margin-top: 5px !important;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  </style>
  