<template>
    <v-row class="mt-2">

        <v-col cols="12">
            <v-sheet :color="!$vuetify.breakpoint.xs ? '#272727' : '#00000000'"
                :class="!$vuetify.breakpoint.xs ? 'mx-auto pa-8 mt-1' : ''" :style="sheetStyle">
                <v-row>

                    <!-- Loading indicator -->
                    <div v-if="isLoading" class="text-center">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </div>

                    <v-col v-else cols="12">
                        <div class="d-flex justify-space-between">

                            <div class="d-flex flex-column align-start">

                                <div class="d-flex align-center">
                                    <span style="font-size: 14px;">{{ $t('wallet.totalBalance') }}</span>
                                    <v-btn icon x-small class="ml-2">
                                        <v-icon>mdi-eye</v-icon>
                                    </v-btn>
                                </div>

                                <div class="mt-2 d-flex align-center">
                                    <h2 style="font-size: 34px; font-weight: 500;">
                                        {{ parseFloat(overviewTotal.spotTotal).toFixed(2) }}
                                    </h2>
                                    <small class="ml-2">USDT</small>
                                </div>

                                <div class="d-flex align-center">
                                    <!-- <span style="font-size: 14px;">Todays PNL </span>

                                    <v-skeleton-loader class="ml-2" v-if="spotPnl === undefined || spotPnl === null"
                                        :loading="!spotPnl" type="text" :width="'40px'"
                                        :height="'20px'"></v-skeleton-loader>

                                    <v-skeleton-loader class="ml-2"
                                        v-if="pnlPercentage === undefined || pnlPercentage === null"
                                        :loading="!pnlPercentage" type="text" :width="'40px'"
                                        :height="'20px'"></v-skeleton-loader>

                                    <div class="ml-2" v-else-if="spotPnl < 0">
                                        <span style="color:#CA3F64">{{ parseFloat(spotPnl).toFixed(2) }}</span>
                                        <span style="color:#CA3F64">({{ parseFloat(pnlPercentage).toFixed(2) }}%)</span>
                                    </div>

                                    <div class="ml-2" v-else-if="spotPnl > 0">
                                        <span style="color:#0ECB81">{{ parseFloat(spotPnl).toFixed(2) }}</span>
                                        <span style="color:#0ECB81">({{ parseFloat(pnlPercentage).toFixed(2) }}%)</span>
                                    </div>

                                    <div class="ml-2" v-else>
                                        <span style="color:#0ECB81">{{ parseFloat(spotPnl).toFixed(2) }}</span>
                                        <span style="color:#0ECB81">({{ parseFloat(pnlPercentage).toFixed(2) }}%)</span>
                                    </div> -->



                                    <!-- <div class="ml-2"> font-size: 14px;">+
                                            {{ parseFloat(totalPnlPercentage).toFixed(2) }}</span
                                        <span v-if="totalPnlPercentage > 0" style="color:#1C1D28;>
                                        <span v-else-if="totalPnlPercentage < 0" style="color:red; font-size: 14px;">
                                            {{ parseFloat(totalPnlPercentage).toFixed(2) }}</span>
                                        <span v-else-if="totalPnlPercentage == 0" style="color:grey; font-size: 14px;">
                                            {{ parseFloat(totalPnlPercentage).toFixed(2) }}</span>
                                    </div> -->
                                </div>

                            </div>

                            <div v-if="!$vuetify.breakpoint.xs">
                                <v-btn @click="goToDeposit()" :ripple="false" color="primary" class="mr-3"
                                    style="width: 116px; text-transform: capitalize; font-size: 14px; color: #1C1D28;">
                                    {{ $t('wallet.deposit') }}
                                </v-btn>

                                <v-btn @click="goToWithdraw()" :ripple="false" class="mr-3"
                                    style="width: 116px; text-transform: capitalize; font-size: 14px;" color="#5E6673">
                                    {{ $t('wallet.withdraw') }}
                                </v-btn>

                                <v-btn @click="goToHistory()" :ripple="false"
                                    style="width: 116px; text-transform: capitalize; font-size: 14px;" color="#5E6673">
                                    {{ $t('wallet.history') }}
                                </v-btn>
                            </div>

                        </div>
                    </v-col>

                    <v-col v-if="$vuetify.breakpoint.xs" cols="12" align="start"
                        style="border-bottom: 0.1px solid #b7bdc6;">
                        <div class="mb-3">
                            <v-row dense>
                                <v-col cols="4">
                                    <v-btn block @click="goToDeposit()" :ripple="false" color="primary"
                                        style="text-transform: capitalize; font-size: 14px; color: #1C1D28; letter-spacing: 0; border-radius: 8px;">
                                        {{ $t('wallet.deposit') }}
                                    </v-btn>
                                </v-col>

                                <v-col cols="4">
                                    <v-btn block @click="goToWithdraw()" :ripple="false" color="#5E6673"
                                        style="text-transform: capitalize; font-size: 14px; letter-spacing: 0; border-radius: 8px;">
                                        {{ $t('wallet.withdraw') }}
                                    </v-btn>

                                </v-col>

                                <v-col cols="4">
                                    <v-btn block @click="goToHistory()" :ripple="false" color="#5E6673"
                                        style="text-transform: capitalize; font-size: 14px; letter-spacing: 0; border-radius: 8px;">
                                        {{ $t('wallet.history') }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>

                </v-row>

            </v-sheet>
        </v-col>

        <v-col v-if="$vuetify.breakpoint.xs" align="start" cols="12" xs="12" md="8" lg="6">
            <SearchBarComponent :search.sync="search" placeholder="Search Coin" />
        </v-col>

        <!-- @click:row="rowClicked($event)" -->
        <v-col v-if="!$vuetify.breakpoint.xs" cols="12">
            <!-- <v-card class="pa-5" color="#272727" style="border-radius: 10px;"> -->
            <v-data-table :loading="isLoading" :headers="headers" :items="filteredData" :items-per-page="30"
                class="transparent">

                <template v-slot:top>
                    <v-toolbar flat color="transparent">
                        <v-row>
                            <v-col cols="12" xs="12" md="6" lg="3">
                                <SearchBarComponent :search.sync="search" placeholder="Search Coin" />
                            </v-col>
                        </v-row>
                    </v-toolbar>
                </template>

                <template v-slot:item.name="{ item }">
                    <div class="py-3 d-flex align-center">
                        <div class="mr-3">
                            <v-avatar size="30">
                                <v-img :src="item.image"></v-img>
                            </v-avatar>
                        </div>

                        <div class="mr-2 d-flex align-center">
                            <!-- <span class="mr-2" style="font-size:16px;">{{ item.coin_pair_name }}</span><br /> -->
                            <span class="">{{ (item.name) }}</span>
                            <!-- <span style="font-size: 14px;"> {{ item.date }}</span><br /> -->
                        </div>
                    </div>
                </template>


                <template v-slot:item.balance="{ item }">
                    <span class="">{{ parseFloat(item.coin_value).toFixed(4) }}</span><br />
                    <!-- <span style="font-size:10px;color:grey">${{ parseFloat(item.usdt_value).toFixed(2) }}</span> -->
                    <!-- <span style="font-size:10px;color:grey">{{ (item.coin_pair_name).replace('USDT', '')}}</span> -->
                    <!-- <span> {{ item.date }}</span><br/> -->
                </template>

                <template v-slot:item.freeze="{ item }">
                    <span class="">{{ parseFloat(item.freeze_value).toFixed(4) }}</span><br />
                    <!-- <span style="font-size:10px;color:grey">${{ parseFloat(item.usdt_value).toFixed(2) }}</span> -->
                    <!-- <span style="font-size:10px;color:grey">{{ (item.coin_pair_name).replace('USDT', '')}}</span> -->
                    <!-- <span> {{ item.date }}</span><br/> -->
                </template>


                <template v-slot:item.actions="{ item }">
                    <v-btn @click="handleOnTradeClick(item.name)" small text class="transparent mr-1">
                        {{ $t('wallet.trade') }}
                        <!-- <v-icon>mdi-chevron-right</v-icon> -->
                    </v-btn>
                    <!-- <v-btn @click="handleOnConvertClick" small text class="transparent mr-1">
                        {{ $t('wallet.convert') }}
                    </v-btn> -->
                    <v-btn @click="handleOnTransferClick" small text class="transparent mr-1">
                        {{ $t('wallet.transfer') }}
                        <!-- <v-icon>mdi-chevron-right</v-icon> -->
                    </v-btn>

                    <v-btn @click="handleOnViewClick(item.name)" small text class="transparent mr-1">
                        {{ $t('wallet.view') }}
                        <!-- <v-icon>mdi-chevron-right</v-icon> -->
                    </v-btn>
                </template>

            </v-data-table>
            <!-- </v-card> -->
        </v-col>

        <v-col v-else cols="12">
            <div v-if="filteredData.length <= 0">
                <div class="d-flex align-center justify-center mt-10" style="height: 100%">
                    <div class="d-flex flex-column align-center justify-center">
                        <v-icon x-large color="#b7bdc6">
                            mdi mdi-note-search-outline
                        </v-icon>
                        <span style="font-size: 12px; color: #b7bdc6">{{ $t('wallet.noRecords') }}</span>
                    </div>
                </div>
            </div>

            <!-- Loading indicator -->
            <div v-if="isLoading" class="text-center">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </div>

            <!-- @click="mobileRowClicked(name)" -->
            <div v-if="!isLoading">
                <v-row align="center" justify="center" class="mt-3" v-for="{ image, name, coin_value, freeze_value} in filteredData"
                    style="border-bottom: 0.1px solid #b7bdc6;">
                    <v-col sm="6" align="start">
                        <div class="d-flex align-center">
                            <v-avatar size="30" class="mr-2">
                                <v-img :src="image"></v-img>
                            </v-avatar>
                            <span style="font-size: 14px; font-weight: 500;"> {{ name }}</span>
                        </div>
                    </v-col>
                    <v-col sm="6" align="start">
                        <span style="font-size: 14px; font-weight: 500;">
                           {{ parseFloat(coin_value).toFixed(4) }}
                       </span>
                   </v-col>
                    <v-col sm="6" align="end">
                        <span style="font-size: 14px; font-weight: 500;">
                            {{ parseFloat(freeze_value).toFixed(2) }}
                        </span>
                        <v-menu offset-y bottom left>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn dark icon v-bind="attrs" v-on="on">
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>

                            <v-list>
                                <!-- <v-list-item v-for="(item, i) in items" :key="i">
                                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                                </v-list-item> -->
                                <v-list-item>
                                    <v-btn block @click="handleOnTradeClick(name)" text class="text-left transparent">
                                        {{ $t('wallet.trade') }}
                                        <!-- <v-icon>mdi-chevron-right</v-icon> -->
                                    </v-btn>
                                </v-list-item>

                                <!-- <v-list-item>
                                    <v-btn block @click="handleOnConvertClick" text class="text-left transparent">
                                        {{ $t('wallet.convert') }}
                                    </v-btn>
                                </v-list-item> -->

                                <v-list-item>
                                    <v-btn block @click="handleOnTransferClick" text class="text-left transparent">
                                        {{ $t('wallet.transfer') }}
                                        <!-- <v-icon>mdi-chevron-right</v-icon> -->
                                    </v-btn>
                                </v-list-item>

                                <v-list-item>
                                    <v-btn block @click="handleOnViewClick(name)" text class="text-left transparent">
                                        {{ $t('wallet.view') }}
                                        <!-- <v-icon>mdi-chevron-right</v-icon> -->
                                    </v-btn>
                                </v-list-item>
                            </v-list>
                        </v-menu>

                        <!-- <span  style="font-size:10px;color:grey">${{ parseFloat(item.usdt_value).toFixed(2) }}</span> -->
                    </v-col>
                    
                </v-row>
            </div>
        </v-col>

        <v-divider />
    </v-row>
</template>

<script>
import SearchBarComponent from '@/components/ui/SearchBarComponent.vue';

export default {

    components: {
        SearchBarComponent,
    },

    data() {
        return {
            search: "",
            data: "",
            headers: [
                {
                    value: "name",
                    text: "Name",
                    align: "start",
                    sortable: true,
                },
                {
                    value: "balance",
                    text: "Balance",
                    sortable: true,
                    align: "end",
                },
                {
                    value: "freeze",
                    text: "Freeze",
                    sortable: true,
                    align: "end",
                },
                {
                    value: "actions",
                    text: "Actions",
                    sortable: true,
                    align: "end",
                }
            ],
        }
    },

    props: {
        spotItems: Array,
        overviewTotal: Object | Array, // Specify the type of the prop
        goToWithdraw: Function,
        goToDeposit: Function,
        goToHistory: Function,
        pnlPercentage: Number,
        spotPnl: Number,
        pnl: Number,
        sheetStyle: [Function, Object],
        isLoading: Boolean,
    },

    methods: {

        handleOnTradeClick(value) {
            const name = (value);
            this.$store.state.selectedCoin = name;
            this.$router.push(`/trades/trade-chart/${name}`)
        },

        handleOnConvertClick() {
            this.$router.push(`/wallet/convert`)
        },

        handleOnTransferClick(value) {
            this.$router.push(`/wallet/transfer`)
        },

        handleOnViewClick(value) {
            const name = (value);
            this.$store.state.selectedCoin = name;
            this.$router.push(`/wallet/${name}`)
        },

        rowClicked(value) {
            const name = (value.name);
            this.$store.state.selectedCoin = name;
            this.$router.push(`/wallet/${name}`);
        },

        mobileRowClicked(value) {
            const name = (value)
            this.$store.state.selectedCoin = name;
            this.$router.push(`/wallet/${name}`);
        }
    },

    computed: {
        filteredData() {
            if (!this.search) {
                return this.spotItems;
            }
            const searchTerm = this.search.toLowerCase();
            return this.spotItems.filter(item => item.name.toLowerCase().includes(searchTerm));
        },
    }

}
</script>

<style>
/* .subtitle-table {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px
} */
</style>