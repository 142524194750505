import Vue from "vue";
import store from "../store";
import VueRouter from "vue-router";
import SignIn from "../views/SignIn.vue";
import SignUp from "../views/SignUp.vue";

//Home Routes
import Scaffold from "../components/main/Scaffold.vue";
import Landing from "../components/main/Landing.vue";

//Home
// import Home from "../views/main/Home/Home.vue";
// import Account from "../views/main/Home/Account.vue";
import Referral from "../views/main/Home/Referral.vue";
import Notifications from "../views/main/Home/Notifications.vue";
import Payments from "../views/main/Home/Payments.vue";
import Tasks from "../views/main/Home/Tasks.vue";
import Rewards from "../views/main/Home/Rewards.vue";
//Market
// import Markets from "../views/main/Markets/Markets.vue";
import TradingData from "../views/main/Markets/Markets.vue";
import Overview from "../views/main/Markets/Overview.vue";
// Settings
import Settings from "../views/main/Settings/Settings.vue";
import Security from "../views/main/Settings/Security.vue";
import Profile from "../views/main/Settings/Profile.vue";
import ChangePassword from "../views/main/Settings/ChangePassword.vue";
import ChangeTransactionPassword from "../views/main/Settings/ChangeTransactionPassword.vue";
import Verify from "../views/main/Settings/Verify.vue";

// Advanced Security Routes
import AccountConnections from "@/views/main/Settings/AccountConnections.vue";
import AntiPhishing from "@/views/main/Settings/AntiPhishing.vue";
import AppAuthorization from "@/views/main/Settings/AppAuthorization.vue";

//Trades
import Trades from "../views/main/Trades/Trades.vue";
import Futures from "../views/main/Trades/Futures.vue";
// import TradeChart from "../views/main/Trades/Futures.vue";
import TradeChart from "../views/main/Trades/TradeChart.vue";
//Wallet
import Convert from "../views/main/Wallet/Convert.vue";
import Transfer from "../views/main/Wallet/Transfer.vue";
import Wallet from "../views/main/Wallet/Wallet/Wallet.vue";
import Deposit from "../views/main/Wallet/Deposit/Deposit.vue";
import Withdraw from "../views/main/Wallet/Withdraw.vue";
import Earn from "../views/main/Wallet/Earn.vue";
import SimpleEarn from "../views/main/Wallet/SimpleEarn.vue";
import AutoInvest from "../views/main/Wallet/AutoInvest.vue";
import Analysis from "../views/main/Wallet/Analysis.vue";
import Transactions from "../views/main/Wallet/Transactions.vue";
import History from "../views/main/Wallet/History.vue";
import Stake from "../views/main/Wallet/Stake.vue";
import Live from "../views/main/Wallet/Live.vue";
import Miner from "../views/main/Wallet/Miner.vue";
import SingleCoin from '../views/main/Wallet/Wallet/WalletCoinPage.vue'

import NotAvailable from '../views/NotAvailable.vue';
import Redirect from "../views/Redirect.vue";

// import { component } from "vue/types/umd";
// import Pay from "../views/main/Wallet/Pay.vue";
// import P2P from "../views/main/Wallet/P2P.vue";
// import Gift from "../views/main/Wallet/Gift.vue";
// import Pool from "../views/main/Wallet/Pool.vue";

Vue.use(VueRouter);

//Admin Routes
let mainRoutes = {
    component: Scaffold,
    path: "/",
    name: "Main",
    children: [
        {
            path: "/auth/callback",
            name: "Landing",

        },
        {
            path: "/",
            name: "Landing",
            component: Landing,
        },
        //Accounts
        {
            path: '/account/referral',
            name: 'Referral',
            component: Referral,
        },
        {
            path: '/account/pay',
            name: 'Payment',
            component: Payments,
        },
        {
            path: '/account/notifications',
            name: 'Notification',
            component: Notifications,
        },
        {
            path: '/account/tasks',
            name: 'Task',
            component: Tasks,
        },
        {
            path: '/account/rewards',
            name: 'Reward',
            component: Rewards,
        },

        //Security
        {
            path: '/settings',
            name: 'Settings',
            component: Settings,
        },

        {
            path: '/account/security',
            name: 'Security',
            component: Security,
        },
        {
            path: "/profile",
            name: "Profile",
            component: Profile,
        },
        {
            path: '/account/verify',
            name: 'Verify',
            component: Verify,
        },
        {
            path: '/account/change_password',
            name: 'ChangePassword',
            component: ChangePassword,
        },
        {
            path: '/account/change_transaction_password',
            name: 'ChangeTransactionPassword',
            component: ChangeTransactionPassword,
        },

        {
            path: '/account/security/account_connections',
            name: 'AccountConnections',
            component: AccountConnections,
        },

        {
            path: '/account/security/anti_phishing',
            name: 'AntiPhishing',
            component: AntiPhishing,
        },

        {
            path: '/account/security/app_authorization',
            name: 'ChangeTransactionPassword',
            component: AppAuthorization,
        },

        //Markets

        {
            path: '/markets',
            name: 'Markets',
            component: () => import("../components/main/markets/Market.vue"),
            children: [
                //AllTab
                {
                    path: 'prices',
                    name: 'Prices',
                    component: () => import("../components/main/markets/Tabs/AllTab.vue"),
                },

                {
                    path: 'pos',
                    name: 'POS',
                    component: () => import("../components/main/markets/Tabs/POSTab.vue"),
                },

                {
                    path: 'pow',
                    name: 'POW',
                    component: () => import("../components/main/markets/Tabs/POWTab.vue"),
                },

                {
                    path: 'innovation',
                    name: 'Innovation',
                    component: () => import("../components/main/markets/Tabs/InnovationTab.vue"),
                },

                {
                    path: 'favorites',
                    name: 'Favorites',
                    component: () => import("../components/main/markets/Tabs/FavoritesTab.vue"),
                },

            ]
        },

        // {
        //     path: "/markets/trading_data",
        //     name: "TradingData",
        //     component: TradingData,
        //     // meta: { requiresAuth: true }
        // },

        // {
        //     path: "/markets/overview",
        //     name: "Overview",
        //     component: Overview,
        // },

        //Trades
        {
            path: "/trades/spot/:coin?",
            name: "Trades",
            component: Trades,
        },
        {
            path: "/trades/futures/:coin?",
            name: "Futures",
            component: Futures,
            meta: { requiresAuth: true }
        },

        {
            path: "/trades/trade-chart/:coin?",
            name: "TradeChart",
            component: TradeChart,
        },

        //Wallet
        {
            path: "/wallet",
            name: "Wallet",
            component: Wallet,
        },
        {
            path: "/wallet/deposit",
            name: "Deposit",
            component: Deposit,
            meta: { requiresAuth: true }
        },
        {
            path: "/wallet/withdraw",
            name: "Withdraw",
            component: Withdraw,
        },
        {
            path: "/wallet/convert",
            name: "Convert",
            component: Convert,
        },
        {
            path: "/wallet/transfer",
            name: "Transfer",
            component: Transfer,
        },
        {
            path: "/history",
            name: "History",
            component: History,
        },

        {
            path: "wallet/transactions",
            name: "Transactions",
            component: Transactions,
        },

        {
            path: "/wallet/earn",
            name: "Earn",
            component: Earn,
        },
        {
            path: "/wallet/simple_earn",
            name: "SimpleEarn",
            component: SimpleEarn,
            meta: { requiresAuth: true }
        },
        {
            path: "/wallet/auto_invest",
            name: "AutoInvest",
            component: AutoInvest,
            meta: { requiresAuth: true }
        },

        {
            path: "/wallet/analysis",
            name: "Analysis",
            component: Analysis,
        },

        {
            path: "/wallet/stake",
            name: "Stake",
            component: Stake
        },

        {
            path: "/wallet/miner",
            name: "Miner",
            component: Miner
        },

        {
            path: "/wallet/live",
            name: "Live",
            component: Live,
        },

        {
            path: "/wallet/:coin?",
            name: "SingleCoin",
            component: SingleCoin
        },

        //Authentications
        {
            path: "/signin",
            name: "SignIn",
            component: SignIn,
        },
        {
            path: "/signup/:referralCode?",
            name: "SignUp",
            component: SignUp,
        },

        //About
        {
            path: '/legal-center',
            name: 'LegalCenter',
            component: () => import("../views/main/About/LegalCenter.vue"),
            children: [
                {
                    path: 'terms',
                    name: 'Terms',
                    component: () => import("../views/main/About/Terms.vue"),
                },
                {
                    path: 'privacy',
                    name: 'Privacy',
                    component: () => import("../views/main/About/Privacy.vue"),
                }
            ]
        },

        {
            path: '/verification',
            name: 'Verification',
            component: () => import("../views/main/Verification/Verification.vue"),
            children: [
                {
                    path: 'overview',
                    name: 'Overview',
                    component: () => import("../views/main/Verification/Overview.vue"),
                },

                {
                    path: 'kyc',
                    name: 'KYC',
                    component: () => import("../views/main/Verification/KYC.vue"),
                },
            ]
        },

        {
            path: '/tier-verification',
            name: 'TierVerification',
            component: () => import("../views/main/TierVerification/TierVerification.vue"),

            // children: [
            //     {
            //         path: 'overview',
            //         name: 'Overview',
            //         component: () => import("../views/main/Verification/Overview.vue"),
            //     },

            //     {
            //         path: 'kyc',
            //         name: 'KYC',
            //         component: () => import("../views/main/Verification/KYC.vue"),
            //     },
            // ]
        },

        {
            path: "/fee-rate",
            name: "fee",
            component: () => import("../views/main/FeeRates/FeeRates.vue"),
        },

        {
            path: "/contactus",
            name: "Contact",
            component: () => import("../views/main/About/Contact.vue"),

        },

        {
            path: '/about',
            name: "About",
            component: () => import('../views/main/About/About.vue')
        },

        {
            path: "/page-unavailable",
            name: "PageUnavailable",
            component: NotAvailable,
        },

        {
            path: "/redirect",
            name: "Redirect",
            component: Redirect,
        },
    ],
};

const routes = [
    mainRoutes,
];

const router = new VueRouter({
    routes,
    mode: "history",
    base: process.env.BASE_URL,

    // This is for the scroll top when click on any router link
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = 0;

        if (to.hash) {
            scrollTo = to.hash;
        } else if (savedPosition) {
            scrollTo = savedPosition.y;
        }

        return goTo(scrollTo);
    },
    // This is for the scroll top when click on any router link

});

// router.beforeEach((to, from, next) => {
//     const isAuthenticated = store.getters.isAuthenticated;

//     if (to.matched.some(record => record.meta.requiresAuth)) {
//         // Check if the user is authenticated
//         if (!isAuthenticated) {
//             // If not authenticated, redirect to the sign-in page
//             next({ path: '/signin' });
//         } else {
//             // If authenticated, proceed to the requested route
//             next();
//         }
//     } else {
//         // If the route does not require authentication, proceed as usual
//         next();
//     }
// });

// router.beforeEach((to, from, next) => {
//     const isAuthenticated = store.getters.isAuthenticated;

//     if (isAuthenticated && (to.name === "SignIn" || to.name === "SignUp")) {
//         next({ name: "Landing" });
//     } else {
//         next();
//     }
// });

// router.beforeEach((to, from, next) => {
//     const isAuthenticated = store.getters.isAuthenticated;

//     if (isAuthenticated && (to.name === "SignIn" || to.name === "SignUp")) {
//         // If user is authenticated and trying to access sign-in or sign-up routes,
//         // redirect to the "Landing" route or any other appropriate route for authenticated users.
//         next({ name: "Landing" });
//     } else if (!isAuthenticated && (to.name === "Landing" || to.name === "Main")) {
//         // If user is not authenticated and trying to access the "Landing" or "Main" routes,
//         // redirect to the sign-in route.
//         next({ name: "SignIn" });
//     } else {
//         // Allow navigation to proceed as usual.
//         next();
//     }
// });

export default router;
