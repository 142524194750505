<template>
    <v-row class="mt-1">
        <v-col cols="12">
            <v-sheet :color="!$vuetify.breakpoint.xs ? '#272727' : '#00000000'"
                :class="!$vuetify.breakpoint.xs ? 'mx-auto pa-8 mt-1' : ''" :style="sheetStyle">
                <v-row>
                    <!-- Loading indicator -->
                    <div v-if="isLoading" class="text-center">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </div>

                    <v-col v-else cols="12">
                        <div class="d-flex justify-space-between">

                            <div class="d-flex flex-column align-start">

                                <div class="d-flex align-center">
                                    <span style="font-size: 14px;">{{ $t('wallet.totalBalance') }}</span>
                                    <v-btn icon x-small class="ml-2">
                                        <v-icon>mdi-eye</v-icon>
                                    </v-btn>
                                </div>

                                <div class="mt-2 d-flex align-center">
                                    <h2 style="font-size: 34px; font-weight: 500;">
                                        {{ parseFloat(overviewTotal.overviewTotal).toFixed(2) }}
                                    </h2>
                                    <small class="ml-2">USDT</small>
                                </div>

                                <div class="d-flex align-center">
                                    <!-- <span style="font-size: 14px;">Todays PNL</span> -->

                                    <!-- <v-skeleton-loader class="ml-2" v-if="totalPnl === undefined || totalPnl === null"
                                        :loading="!totalPnl" type="text" :width="'40px'"
                                        :height="'20px'"></v-skeleton-loader>

                                    <v-skeleton-loader class="ml-2"
                                        v-if="totalPnlPercentage === undefined || totalPnlPercentage === null"
                                        :loading="!totalPnlPercentage" type="text" :width="'40px'"
                                        :height="'20px'"></v-skeleton-loader>


                                    <div class="ml-2" v-else-if="totalPnl < 0">
                                        <span style="color:#CA3F64 ">{{ parseFloat(totalPnl).toFixed(2) }}</span>
                                        <span style="color:#CA3F64 ">({{ parseFloat(totalPnlPercentage).toFixed(2) }}%)
                                        </span>
                                    </div>
                                    <div class="ml-2" v-else-if="totalPnl > 0">
                                        <span style="color:#0ECB81 ">{{ parseFloat(totalPnl).toFixed(2) }}</span>
                                        <span style="color:#0ECB81 ">({{ parseFloat(totalPnlPercentage).toFixed(2) }}%)
                                        </span>
                                    </div>
                                    <div class="ml-2" v-else>
                                        <span style="color:#0ECB81 ">{{ parseFloat(totalPnl).toFixed(2) }}</span>
                                        <span style="color:#0ECB81 ">({{ parseFloat(totalPnlPercentage).toFixed(2) }}%)
                                        </span>
                                    </div> -->


                                    <!-- <div class="ml-2">
                                        <span v-if="totalPnlPercentage > 0" style="color:#1C1D28; font-size: 14px;">+
                                            {{ parseFloat(totalPnlPercentage).toFixed(2) }}</span>
                                        <span v-else-if="totalPnlPercentage < 0" style="color:red; font-size: 14px;">
                                            {{ parseFloat(totalPnlPercentage).toFixed(2) }}</span>
                                        <span v-else-if="totalPnlPercentage == 0" style="color:grey; font-size: 14px;">
                                            {{ parseFloat(totalPnlPercentage).toFixed(2) }}</span>
                                    </div> -->
                                </div>
                            </div>

                            <div v-if="!$vuetify.breakpoint.xs">
                                <v-btn @click="goToDeposit()" :ripple="false" class="mr-3"
                                    style="width: 116px; text-transform: capitalize; font-size: 14px; color: #1C1D28;"
                                    color="primary">
                                    {{ $t('wallet.deposit') }}
                                </v-btn>

                                <v-btn @click="goToWithdraw()" :ripple="false" class="mr-3"
                                    style="width: 116px; text-transform: capitalize; font-size: 14px;" color="#5E6673">
                                    {{ $t('wallet.withdraw') }}
                                </v-btn>

                                <v-btn @click="goToHistory()" :ripple="false"
                                    style="width: 116px; text-transform: capitalize; font-size: 14px;" color="#5E6673">
                                    {{ $t('wallet.history') }}
                                </v-btn>
                            </div>
                        </div>
                    </v-col>

                    <v-col v-if="$vuetify.breakpoint.xs" cols="12" align="start"
                        style="border-bottom: 0.1px solid #b7bdc6;">
                        <div class="mb-3">
                            <v-row dense>
                                <v-col cols="4">
                                    <v-btn block @click="goToDeposit()" :ripple="false" color="primary"
                                        style="text-transform: capitalize; font-size: 14px; color: #1C1D28; letter-spacing: 0; border-radius: 8px;">
                                        {{ $t('wallet.deposit') }}
                                    </v-btn>
                                </v-col>

                                <v-col cols="4">
                                    <v-btn block @click="goToWithdraw()" :ripple="false" color="#5E6673"
                                        style="text-transform: capitalize; font-size: 14px; letter-spacing: 0; border-radius: 8px;">
                                        {{ $t('wallet.withdraw') }}
                                    </v-btn>

                                </v-col>

                                <v-col cols="4">
                                    <v-btn block @click="goToHistory()" :ripple="false" color="#5E6673"
                                        style="text-transform: capitalize; font-size: 14px; letter-spacing: 0; border-radius: 8px;">
                                        {{ $t('wallet.history') }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>

                </v-row>
            </v-sheet>
        </v-col>

        <v-col v-if="!$vuetify.breakpoint.xs" cols="12" xs="12" sm="6" md="4" lg="4">
            <v-card class="pa-5 mb-5" color="#272727" style="border-radius: 3px;">
                <v-card-text>

                    <div>
                        <!-- Loading indicator -->
                        <div v-if="isNaN(overviewTotal.spotTotal) || overviewTotal.spotTotal === undefined || overviewTotal.spotTotal === null"
                            class="text-center">
                            <v-progress-circular indeterminate color="primary"></v-progress-circular>
                        </div>

                        <!-- Actual content -->
                        <div v-else>
                            <div class="d-flex flex-column">
                                <span>{{ $t('wallet.spot') }}</span>
                                <h4>${{ parseFloat(overviewTotal.spotTotal).toFixed(2) }}</h4>
                            </div>

                            <div class="mt-5 d-flex align-center ">
                                <!-- <v-chip color="danger" label class="mr-5">
                                    <v-icon v-if="spotPnl > 0" class="mr-2" color="green">mdi-arrow-top-right</v-icon>
                                    <v-icon v-if="spotPnl < 0" class="mr-2" color="red">mdi-arrow-bottom-right</v-icon>
                                    <span>{{ parseFloat(spotPnl).toFixed(2) }}</span>
                                </v-chip>

                                <span>Today's PNL</span> -->
                            </div>
                        </div>
                    </div>

                </v-card-text>
            </v-card>
        </v-col>

        <v-col v-if="!$vuetify.breakpoint.xs" cols="12" xs="12" sm="6" md="4" lg="4">
            <v-card class="pa-5 mb-5" color="#272727" style="border-radius: 3px;">
                <v-card-text>
                    <!-- Loading indicator -->
                    <div v-if="isNaN(overviewTotal.futureTotal) || overviewTotal.futureTotal === undefined || overviewTotal.futureTotal === null"
                        class="text-center">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </div>

                    <div v-else>
                        <div class="d-flex flex-column">
                            <span>{{ $t('wallet.future') }}</span>
                            <h4>${{ parseFloat(overviewTotal.futureTotal).toFixed(2) }}</h4>
                        </div>

                        <div class="mt-5 d-flex align-center ">
                            <!-- <v-chip color="danger" label class="mr-5">
                                <v-icon v-if="futurePnl > 0" class="mr-2" color="green">mdi-arrow-top-right</v-icon>
                                <v-icon v-if="futurePnl < 0" class="mr-2" color="red">mdi-arrow-bottom-right</v-icon>
                                <span> {{ parseFloat(futurePnl).toFixed(2) }}</span>
                            </v-chip>

                            <span>Today's PNL</span> -->
                        </div>
                    </div>

                </v-card-text>
            </v-card>
        </v-col>

        <v-col v-if="!$vuetify.breakpoint.xs" cols="12" xs="12" sm="6" md="4" lg="4">
            <v-card class="pa-5 mb-5" color="#272727" style="border-radius: 3px;">
                <v-card-text>

                    <!-- Loading indicator -->
                    <div v-if="isLoading" class="text-center">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </div>

                    <div v-else>
                        <div class="d-flex flex-column">
                            <span>{{ $t('wallet.earn') }}</span>
                            <h4>${{ parseFloat(overviewTotal.earnTotal).toFixed(2) }}</h4>
                        </div>

                        <div class="mt-5 d-flex align-center ">
                            <!-- <v-chip color="danger" label class="mr-5">
                                <v-icon class="mr-2" color="green">mdi-arrow-top-right</v-icon>
                                <span>---</span>
                            </v-chip> -->

                            <!-- <span>Earn</span> -->
                        </div>
                    </div>

                </v-card-text>
            </v-card>
        </v-col>



        <!-- mobile list -->
        <v-col class="mb-5 mt-2" v-if="$vuetify.breakpoint.xs" cols="12">

            <div class="mb-3">
                <span style="font-size:14px; font-weight: 500;"> {{ $t('wallet.account') }}</span>
            </div>

            <v-row @click=" rowClicked('one')" style="border-bottom: 0.1px solid #b7bdc6;" class="py-2">
                <v-col align="start" cols="6">
                    <div class="d-flex flex-column">
                        <span style="font-size:14px; font-weight: 500;"> {{ $t('wallet.spot') }}</span>
                        <!-- <span style="font-size: 12px; color: #b7bdc6 ;" class="mt-2">Daily PNL%</span> -->
                    </div>
                </v-col>
                <v-col align="end" cols="6">
                    <div class="d-flex flex-column">
                        <span style="font-size:14px; font-weight: 500;">
                            ${{ parseFloat(overviewTotal.spotTotal).toFixed(2) }}
                        </span>
                        <!-- <span class="mt-2"
                            style="font-size: 12px; color: #0ecb81 ; font-weight: 500;">+$350.59(+9.92%)</span> -->
                    </div>
                </v-col>
            </v-row>

            <v-row @click="rowClicked('two')" style=" border-bottom:0.1px solid #b7bdc6;" class="py-2">
                <v-col align="start" cols="6">
                    <span style="font-size:14px; font-weight: 500;">{{ $t('wallet.earn') }}</span>
                </v-col>

                <v-col v-if="isLoading" align="end" cols="6">
                    <!-- <v-skeleton-loader max-width="100" type="text"></v-skeleton-loader> -->
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </v-col>

                <v-col v-else align="end" cols="6">
                    ${{ parseFloat(overviewTotal.earnTotal).toFixed(2) }}
                </v-col>
            </v-row>

            <v-row @click=" rowClicked('three')" style="border-bottom: 0.1px solid #b7bdc6;" class="py-2">
                <v-col align="start" cols="6">
                    <span style="font-size:14px; font-weight: 500;">{{ $t('wallet.future') }}</span>
                </v-col>

                <v-col v-if="isLoading" align="end" cols="6">
                    <!-- <v-skeleton-loader max-width="100" type="text"></v-skeleton-loader> -->
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </v-col>

                <v-col v-else align="end" cols="6">
                    ${{ parseFloat(overviewTotal.futureTotal).toFixed(2) }}
                </v-col>
            </v-row>
        </v-col>

    </v-row>
</template>

<script>
import SearchBarComponent from '@/components/SidebarComponent.vue';

export default {

    components: {
        SearchBarComponent
    },

    data() {
        return {

        }
    },

    props: {
        overviewTotal: Object | Array, // Specify the type of the prop
        goToWithdraw: Function,
        goToDeposit: Function,
        goToHistory: Function,
        totalPnlPercentage: [Number, String],
        totalPnl: [Number, String],
        spotPnl: Number,
        futurePnl: Number,
        sheetStyle: [Function, Object],
        isLoading: Boolean,
    },

    methods: {
        rowClicked(tab) {
            // Emit an event to the parent component to change the active tab
            this.$emit('change-tab', tab);
            // console.log(tab)
        },
    },
}
</script>