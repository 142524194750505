<template>
    <v-app-bar class="elevation-0 transparent">
        <v-text-field v-model="localSearch" prepend-inner-icon="mdi-magnify" class="mt-6" placeholder="search" outlined
            dense @input="updateSearch" />
    </v-app-bar>
</template>

<script>
export default {
    props: {
        search: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        localSearch: "",
    }),

    mounted() {
        // Initialize localSearch with the value of search prop
        this.localSearch = this.search;
    },
    beforeDestroy() { },

    methods: {
        updateSearch() {
            this.$emit("update:search", this.localSearch);
        },
    },
};
</script>
