<template>
    <v-layout class="overflow-visible" style="height: 56px">
      <v-bottom-navigation v-model="value" color="primary" active>
        <v-btn>
          <v-icon>mdi-history</v-icon>
  
          Recents
        </v-btn>
  
        <v-btn>
          <v-icon>mdi-heart</v-icon>
  
          Favorites
        </v-btn>
  
        <v-btn>
          <v-icon>mdi-map-marker</v-icon>
  
          <span>Nearby</span>
        </v-btn>
      </v-bottom-navigation>
    </v-layout>
  </template>
  
  <script>
    export default {
      data: () => ({ value: 0 }),
    }
  </script>