<template>
  <v-row>
    <v-col>
      <h1 class="font-weight-bold">Earn daily rewards on your idle tokens</h1>
      <p>Simple & Secure. Search popular coins and start earning.</p>
      <v-row>
        <v-col cols="3" v-for="coin in filteredCoins" :key="coin.id">
          <v-card shaped class="text-center pa-4">
            <v-avatar size="70"><img :src="coin.image" /></v-avatar>
            <v-card-text class="text-center">
              <h5 class="font-weight-bold white--text">{{ coin.name }}</h5>
              APR
              <h4 class="greenPrimary--text text--lighten-1">
                {{ coin.percent }}
              </h4>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn icon color="secondary" text @click="goTo()">
                <v-icon large color="primary">mdi-arrow-right</v-icon>
              </v-btn>
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data: () => ({
    loading: true,
  }),
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
  computed: {
    marketCoins() {
      return this.$store.getters.marketCoins;
    },
    filteredCoins() {
      return this.marketCoins.filter((coin) => coin.type === "MAIN");
    },
  },
  methods: {
    goTo() {
      this.$router.push("/wallet/simple_earn");
    },
  },
};
</script>
<style>
.v-tab {
  min-width: 60px !important;
}
.theme--dark.v-tabs-items {
  background-color: transparent !important;
}
</style>
